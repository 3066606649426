$bgColor: yellow;
$bgColor: "bgColor";
$mainColor: "mainColor";
$mainColorHoverText: "mainColorHoverText";
$secundaryColor: "secundaryColor";
$secundaryColorHoverText: "secundaryColorHoverText";
$tertiaryColor: "tertiaryColor";
$tertiaryColorHoverText: "tertiaryColorHoverText";
$quaternaryColor: "quaternaryColor";
$quaternaryColorHoverText: "quaternaryColorHoverText";
$urlColor: "urlColor";
$textColor: "textColor";
$bannerColorMain: "bannerColorMain";
$bannerColor: "bannerColor";
$bannerColor00: "bannerColor00";
$bannerColortext: "bannerColortext";
$homeHighlightHoverBg: "homeHighlightHoverBg";
$homeHighlightHoverText: "homeHighlightHoverText";
$titleColor: "titleColor";
$bonusItemBtnBg: "bonusItemBtnBg";
$bonusItemBtnText: "bonusItemBtnText";
$bonusItemBtnReviewBg: "bonusItemBtnReviewBg";
$bonusItemBtnReviewBorder: "bonusItemBtnReviewBorder";
$bonusItemBtnReviewText: "bonusItemBtnReviewText";
$cardsBtnBg: "cardsBtnBg";
$cardsBtnText: "cardsBtnText";
$widgetBg: "widgetBg";
$widgetText: "widgetText";
$headerBg: "headerBg";
$menuBg: "menuBg";
$menuText: "menuText";
$footerBg: "footerBg";
$footerLinksText: "footerLinksText";
$footerLine: "footerLine";
$footerBottom: "footerBottom";
$footerBottomText: "footerBottomText";
$bgColor: var(--styleColorsBg);
$mainColor: var(--styleColorsMain);
$mainColorHoverText: var(--styleColorsMainText);
$secundaryColor: var(--styleColorsSecondary);
$secundaryColorHoverText: var(--styleColorsSecundaryText);
$tertiaryColor: var(--styleColorsTertiary);
$tertiaryColorHoverText: var(--styleColorsTertiaryText);
$quaternaryColor: var(--styleColorsQuaternary);
$quaternaryColorHoverText: var(--styleColorsQuaternaryText);
$urlColor: var(--styleColorsUrl);
$textColor: var(--styleColorsText);
$bannerColorMain: var(--styleColorsBannerHomeMain);
$bannerColor: var(--styleColorsBannerHome);
$bannerColor00: var(--styleColorsBannerHomeAlpha);
$bannerColortext: var(--styleColorsBannerHomeText);
$homeHighlightHoverBg: var(--styleColorsHomeHighlightBg);
$homeHighlightHoverText: var(--styleColorsHomeHighlightText);
$titleColor: var(--styleColorsTitle);
$bonusItemBtnBg: var(--styleColorsBonusItemButtonBg, var(--styleColorsMain));
$bonusItemBtnText: var(--styleColorsBonusItemButtonText, #ffffff);
$bonusItemBtnReviewBg: var(--styleColorsBonusItemButtonReviewBg, transparent);
$bonusItemBtnReviewBorder: var(--styleColorsBonusItemButtonReviewBorder, var(--styleColorsMain));
$bonusItemBtnReviewText: var(--styleColorsBonusItemButtonReviewText, var(--styleColorsMain));
$cardsBtnBg: var(--styleColorsCardsButtonBg);
$cardsBtnText: var(--styleColorsCardsButtonText);
$widgetBg: var(--styleColorsWidgetBg);
$widgetText: var(--styleColorsWidgetText, var(--styleColorsText));
$headerBg: var(--styleColorsHeader);
$menuBg: var(--styleColorsMenu);
$menuText: var(--styleColorsMenuText);
$footerBg: var(--styleColorsFooter);
$footerLinksText: var(--styleColorsFooterLinksText);
$footerLine: var(--styleColorsFooterLine);
$footerBottom: var(--styleColorsFooterBarBottom);
$footerBottomText: var(--styleColorsFooterBarBottomText);