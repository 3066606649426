// Loading
@keyframes loadingAnim {
    0% {
        opacity: 1;
        transform: translate(0 0);
    }
    49.99% {
        opacity: 1;
        transform: translate(30px, 0);
    }
    50% {
        opacity: 0;
        transform: translate(30px, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, 0);
    }
}
@keyframes loadingTrans {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(30px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
.loadingSpin div {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 10px;
    left: 10px;
}
.loadingSpin div:nth-child(1) {
    animation: loadingTrans 1s linear infinite;
    animation-delay: -0.5s;
    background: $mainColor;
}
.loadingSpin div:nth-child(2) {
    animation: loadingTrans 1s linear infinite;
    animation-delay: 0s;
    background: $secundaryColor;
}
.loadingSpin div:nth-child(3) {
    animation: loadingAnim 1s linear infinite;
    animation-delay: -0.5s;
    background: $mainColor;
}
.loadingSpinBall {
    width: 80px;
    height: 40px;
    display: inline-block;
    overflow: hidden;
    background: none;
    margin: 1.5rem auto;
}
.loadingSpin {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.loadingSpin div {
    box-sizing: content-box;
}
