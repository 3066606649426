.breadcrumbSection {
    height: 47px;
    display: flex;
    align-items: center;
    background: $bgColor;

    ul {
        margin: 0;
        display: flex;
        align-items: center;
        li {
            font-size: 14px;
            color: #6f6e6e;
            text-transform: capitalize;
            &:after {
                content: "/";
                margin-right: 4px;
            }
            a {
                color: inherit;
            }
            &:last-child::after {
                display: none;
            }
        }
    }
}
