.categories {
    display: grid;
    //grid-template-columns: 20% 1fr;
    .name {
        font-size: 24px;
        font-weight: 900;
        display: flex;
        flex-direction: column;
        padding-right: 70px;
        text-align: center;
        .title {
            font-size: 50px;
            line-height: 45px;
            text-transform: capitalize;
        }
    }
    .search {
        position: relative;
        padding-right: 20px;
        display: none;
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;
            color: black;
            i {
                font-size: 18px;
            }
        }
        input {
            width: 100%;
            height: 48px;
            background: #f9f9fa;
            border-radius: 5px;
            border: 1px solid #e8e8e8;
            padding-left: 45px;
        }
        .clean {
            position: absolute;
            top: 50%;
            right: 40px;
            text-decoration: underline;
            transform: translateY(-50%);
            font-size: 1rem;
            color: $secundaryColor;
        }
        .action {
            display: none;
        }
    }
    ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 20px;
        li {
            flex: 1 1 0px;
            a {
                text-align: center;
                background: #f9f9fa;
                border-radius: 5px;
                border: 1px solid #e8e8e8;
                align-items: center;
                display: flex;
                justify-content: center;
                font-size: $fontSizeSmallText;
                font-weight: 500;
                transition: all 300ms ease-in-out;
                height: 100%;
                flex-direction: column;
                height: 100px;
                text-transform: capitalize;
                img {
                    height: 38px;
                    margin-bottom: 5px;
                }
                &:hover {
                    background: $secundaryColor;
                    border: 1px solid $secundaryColor;
                }
            }
            &.active {
                a {
                    background: $secundaryColor;
                    border: 1px solid $secundaryColor;
                }
            }
        }
    }
    &.hasSearch {
        grid-template-columns: 60% 1fr;
        .name {
            display: none;
        }
        .search {
            display: block;
        }
        ul {
            column-gap: 10px;
            li {
                a {
                    height: 48px;
                    img {
                        height: 28px;
                    }
                    .title {
                        display: none;
                    }
                }
            }
        }
    }
}
