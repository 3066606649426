.posts {
    .bannerPost {
        position: relative;
        background-repeat: no-repeat;
        background-position: left center;
        color: white;
        background-size: auto 100%;
        background: $bannerColor;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(90deg, $bannerColor00 0%, $bannerColor 30%, $bannerColor 50%, t$bannerColor 85%, $bannerColor 100%);
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgb(0, 0, 0);
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
            opacity: 0.5;
            z-index: 2;
        }
        .data {
            position: relative;
            z-index: 3;
            height: 260px;
            display: flex;
            align-items: center;
            &.smallBanner {
                min-height: 140px;
                height: auto;
                padding: 2rem 0px;
                .title {
                    padding-left: 20px;
                }
            }
            .row {
                width: 100%;
            }
            .title {
                padding-left: 70px;
                h1 {
                    margin: 0;
                    padding: 0;
                    font-size: $fontSizeH1;
                    line-height: $fontLHeightH1;
                    font-weight: 700;
                    letter-spacing: -1px;
                    color: #ffffff;
                }
                .subtitle {
                    font-size: 2rem;
                    margin-top: 10px;
                }
            }
        }
    }

    .descriptionSection {
        background: rgba(#1d2541, 0.02);
        text-align: center;
        padding: 50px 0 100px;
        h1 {
            padding: 0;
            margin: 0;
            font-size: 32px;
            font-weight: 900;
            margin-bottom: 30px;
        }
        p {
            font-size: 14px;
            line-height: 24px;
        }
    }

    .categories {
        ul {
            li {
                a {
                    background: #f9f9fa;
                    border: 1px solid #e8e8e8;
                    &:hover {
                        background: $secundaryColor;
                        border: 1px solid $secundaryColor;
                    }
                }
                &.active {
                    a {
                        background: $secundaryColor;
                        border: 1px solid $secundaryColor;
                    }
                }
            }
        }
    }

    .sectionBtnMore {
        display: block;
        text-align: center;
        padding: 2rem 0px;
        .btnViewMore {
            display: inline-block;
            padding: 10px 3rem;
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            border-radius: 5px;
            text-transform: uppercase;
            background-color: $quaternaryColor;

            &:hover {
                opacity: 0.9;
                cursor: pointer;
            }
        }
    }
}
