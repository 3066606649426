.scroll-progress-container {
    width: 100%;
    height: 3px;
    background-color: transparent;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 99999;
    .scroll-progress {
        width: 0px;
        height: 3px;
        background-color: $tertiaryColor;
    }
}
