.bettingList {
    display: grid;
    grid-template-columns: 15% 1fr;
    column-gap: 50px;
    .name {
        color: #03102c;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        text-align: right;
        span {
            font-size: 36px;
            line-height: 36px;
            font-weight: 900;
            color: $mainColor;
        }
    }
    ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 20px;
        li {
            flex: 1 1 0px;
            a {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 300ms ease-in-out;
                img {
                    max-width: 70%;
                    max-height: 70%;
                }
                &:hover {
                    opacity: 0.8;
                }
                border: 1px solid #f5f5f5;
            }
            &.showMore {
                a {
                    .icon {
                        position: relative;
                        color: white;
                        i {
                            position: relative;
                            z-index: 2;
                        }
                        &:after {
                            content: "";
                            width: 30px;
                            height: 30px;
                            position: absolute;
                            z-index: 0;
                            border-radius: 50%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background: $mainColor;
                        }
                    }
                    &:hover {
                        color: white;
                        background: $mainColor;

                        .icon {
                            color: $mainColor;

                            &:after {
                                background: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
