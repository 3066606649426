.postCard {
    width: 100%;
    border-radius: 5px;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    color: white;
    position: relative;
    height: 100%;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgb(16, 39, 90);
        background: linear-gradient(180deg, rgba(16, 39, 90, 0.47) 0%, rgba(4, 20, 52, 1) 100%);
        opacity: 0.8;
        z-index: 1;
    }
    a {
        position: relative;
        z-index: 2;
        display: flex;
        padding: 30px 20px 20px 40px;
        flex-direction: column;
        align-items: flex-start;
        min-height: 100%;
    }
    .cat {
        height: 30px;
        margin-bottom: 20px;
        .image {
            width: 26px;
            height: 26px;
            position: absolute;
            top: 15px;
            right: 15px;
            background: $secundaryColor;
        }
    }
    .tag {
        height: 100%;
        font-size: $fontSizeBtn;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 20px;
        display: flex;
        align-items: center;
        flex: 1;
        border-radius: 2px;
        color: $secundaryColorHoverText;
        background: $secundaryColor;
    }
    .title {
        font-size: $fontSizeSmallH1;
        line-height: $fontLHeightSmallH1;
        font-weight: 500;
        max-width: 70%;
        margin-bottom: 20px;
        flex: 1;
        .text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5; /* number of lines to show */
            line-clamp: 5;
            -webkit-box-orient: vertical;
        }
    }
    .description {
        font-size: 20px;
        line-height: 24px;
        max-width: 70%;
        flex: 1;
    }
    .action {
        position: absolute;
        bottom: 15px;
        right: 20px;
        .button {
            text-transform: uppercase;
            padding: 0px 20px;
            background: $cardsBtnBg;
            color: $cardsBtnText;
        }
    }
}

.cards {
    ul {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        row-gap: 20px;
        column-gap: 15px;
        .postCard {
            a {
                padding: 15px 20px;
                height: 260px;
            }
            .cat {
                height: 25px;
                .tag {
                    padding: 5px 10px;
                    font-size: $fontSizeSmallBtn;
                    line-height: $fontLHeightSmallBtn;
                    border-radius: 2px;
                    display: inline-block;
                    .letter {
                        display: none;
                    }
                }
            }
            .title {
                font-size: $fontSizeTitleCards;
                max-width: 95%;
                line-height: $fontLHeightTitleCards;
                font-weight: 500;
                letter-spacing: -0.5px;
            }
            .action {
                position: relative;
                right: auto;
                bottom: auto;
                vertical-align: bottom;
                .button {
                    padding: 0 20px;
                    font-size: $fontSizeSmallBtn;
                    line-height: $fontLHeightSmallBtn;
                }
            }
        }
    }
}
