.widget {
    padding: 1rem 0px 1rem;
    display: inline;
    position: relative;
    width: 100%;

    &.affiliate {
        border: none;
        display: inline;
        padding: 1rem 0px 1rem;
        margin: 1px;
    }

    .widgetBody {
        position: relative;
        width: 100%;
        &.widget_text {
            border-radius: 5px;
            display: flex;
            background: #0b3691;
            align-content: center;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 2rem 5vw 2.5rem 5vw;
            text-align: center;
            color: #ffffff;
            background-image: url("/images/widgets/widget_points_left.svg"), url("/images/widgets/widget_points_right.svg"),
                url("/images/widgets/widget_arrows_left.svg"), url("/images/widgets/widget_arrows_right.svg");
            background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
            background-position: 15px calc(100% - 15px), calc(100% - 15px) calc(100% - 15px), center left, center right;
            background-size: 25%, 25%, 35%, 50%;

            .label {
                position: absolute;
                left: 20px;
                top: -14px;
                padding: 4px 20px;
                background-color: #f9dc1c;
                border-radius: 4px;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                color: #000000;
            }

            .title {
                font-size: 28px;
                font-weight: 800;
                line-height: 36px;
                text-align: center;
            }
            .description {
                padding-top: 0.5rem;
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
            }
        }

        &.widget_affiliate {
            border-radius: 5px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            text-align: left;
            border: 1px solid #e0e0e0;
            column-gap: 20px;
            transition: all 0.4s ease-in-out;
            position: sticky;
            top: 90px;
            z-index: 99;
            background: $widgetBg !important;
            color: $widgetText;
            .label {
                position: absolute;
                left: 20px;
                top: -12px;
                padding: 4px 20px;
                background-color: #f9dc1c;
                border-radius: 4px;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                color: #000000;
                line-height: 16px;
            }

            .logo {
                min-width: 25%;
                height: 80px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ffffff;
                text-decoration: none;
                font-weight: 600;
                img {
                    margin: 0px;
                    padding: 0px;
                    width: 90%;
                    max-width: 150px;
                    object-fit: contain;
                    max-height: 90%;
                }
            }
            .bonusMobile {
                display: none;
            }
            .title {
                font-size: 18px;
                font-weight: 600;
                line-height: 22px;
                color: #000000;
            }
            .action {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                a {
                    white-space: nowrap;
                    margin: 5px 0;
                    width: 100%;
                    padding: 0 20px;
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #ffffff;
                    text-decoration: none;
                    background-color: $mainColor;
                    &:hover {
                        opacity: 0.9;
                    }
                }
            }
        }

        &.widget_image {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            .widgetInside {
                padding: 2rem 3rem;
                position: relative;
                z-index: 2;
                .title {
                    padding: 0px 2vw;
                    font-size: 22px;
                    font-weight: 800;
                    line-height: 28px;
                }
                .description {
                    padding: 0.5rem 2vw 0px 2vw;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
                .image {
                    img {
                        width: 100%;
                    }
                }
            }

            .bgWidget {
                position: absolute;
                z-index: 1;
                top: 0px;
                left: 0px;
                right: 0px;
                border-radius: 5px;
                background: #0b3691;
                width: 100%;
                height: 50%;
                background-image: url("/images/widgets/widget_points_bottom.svg"), url("/images/widgets/widget_points_top.svg"),
                    url("/images/widgets/widget_arrows_down.svg"), url("/images/widgets/widget_arrows_up.svg");
                background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
                background-position: 15px calc(100% - 15px), calc(100% - 15px) 15px, bottom left, top right;
                background-size: 12px, 12px, 12%, 12%;
            }
        }

        &.widget_list {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            background: $widgetBg;
            color: $widgetText;

            ul.listWidget {
                list-style: none;
                padding: 0px;
                margin: 0px;
                display: grid;
                align-content: center;
                justify-content: center;
                align-items: center;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                column-gap: 12%;
                grid-row-gap: 12%;
                li {
                    padding: 0px;
                    margin: 0px;
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                }
            }
            .title {
                font-size: 22px;
                font-weight: 700;
                line-height: 30px;
                color: #081131;
                .number {
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 600px;
                    color: #f9dc1c;
                    background: #0b3691;
                    border-radius: 100px;
                    text-align: center;
                    margin-right: 5px;
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: -4px;
                }
            }
            .description {
                padding-top: 0.5rem;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: #081131;
            }
        }

        &.widget_payments {
            display: flex;
            align-content: center;
            align-items: stretch;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            border-radius: 6px;
            padding: 1.2rem;
            border: 1px solid #e0e0e0;
            background: $widgetBg;
            color: $widgetText;

            .infosList {
                display: flex;
                flex-direction: row;
                align-content: stretch;
                align-items: center;
                justify-content: flex-start;
                column-gap: 20px;
                padding-bottom: 0.2rem;
                .image {
                    padding: 5px 1rem;
                    background: #f9f9fa;
                    border-radius: 5px;
                    width: 40%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-height: 50px;
                        object-fit: contain;
                        width: 100%;
                        margin: 0.7rem 0px;
                    }
                }
                .title {
                    font-size: $fontSizeH2;
                    line-height: $fontSizeH2;
                    font-weight: 900;
                    text-align: right;
                    width: 100%;
                }
            }
            .listPayments {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: flex-start;
                align-items: flex-start;
                justify-content: space-around;
                column-gap: 20px;
                .sectionList {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-wrap: nowrap;
                    .title {
                        font-size: $fontSizeText;
                        line-height: $fontLHeightText;
                        text-transform: capitalize;
                        text-align: center;
                        font-weight: 700;
                        padding: 10px 0px 4px 0px;
                    }
                }
                .card {
                    .card-header {
                        cursor: pointer;
                        background: #f9f9fa;
                        border-radius: 5px;
                        border: 0px;
                        margin: 5px 0px;
                        padding: 15px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-content: center;
                        align-items: center;
                        column-gap: 30px;
                        .image {
                            padding: 0px;
                            border-radius: 5px;
                            max-width: 120px;
                            width: 30%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                max-height: 26px;
                                object-fit: contain;
                                width: 100%;
                                margin: 0px;
                            }
                        }
                        .name {
                            text-transform: capitalize;
                            font-weight: 400;
                            width: 100%;
                            color: $widgetText;
                            font-size: $fontSizeText;
                            line-height: $fontLHeightText;
                        }
                        .arrow {
                            .btnArrow {
                                color: #ffffff;
                                border-radius: 4px;
                                line-height: 28px;
                                text-align: center;
                                width: 24px;
                                height: 24px;
                                background: $titleColor;
                            }
                        }
                    }
                }
            }

            .title {
                font-size: 22px;
                font-weight: 700;
                line-height: 30px;
                color: #081131;
                .number {
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 600px;
                    color: #f9dc1c;
                    background: #0b3691;
                    border-radius: 100px;
                    text-align: center;
                    margin-right: 5px;
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: -4px;
                }
            }
            .description {
                padding-top: 0.5rem;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: #081131;
            }
        }

        &.widget_banner_big {
            position: relative;
            &:after {
                content: "";
                width: 100%;
                height: 10vh;
                position: absolute;
                z-index: 0;
                bottom: 0px;
                left: 0;
                background: $bannerColor;
                background: linear-gradient(180deg, $bannerColor00 0%, $bannerColor 100%);
            }
            .bannerSection {
                color: white;
                height: 50vh;
                min-height: 400px;
                background-size: cover;
                background-position: top center;
                position: relative;
                display: flex;
                align-items: center;
                background-color: $bannerColor;
                &:after,
                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                    opacity: 0.8;
                    background: $bannerColor;
                    background: linear-gradient(90deg, $bannerColor 0%, $bannerColor 15%, $bannerColor00 50%, $bannerColor 85%, $bannerColor 100%);
                }
                &:before {
                    z-index: 1;
                }
                .title {
                    position: relative;
                    z-index: 3;
                    line-height: 70px;
                    margin-top: 50px;
                    h1 {
                        font-size: $fontHomeSizeH1;
                        line-height: $fontHomeLHeightH1;
                        color: $bannerColortext;
                        strong {
                            color: $secundaryColor;
                        }
                        em {
                            font-size: $fontHomeSizeH1Small;
                            font-weight: 800;
                            line-height: $fontHomeLHeightH1Small;
                            font-style: normal;
                        }
                    }
                }

                p {
                    width: 70%;
                    position: relative;
                    z-index: 3;
                    margin-top: 20px;
                    font-size: $fontSizeText;
                    line-height: $fontLHeightText;
                    max-width: 60%;
                    color: $bannerColortext;
                }
            }
        }

        &.widget_banner_small {
            &:after {
                content: "";
                width: 100%;
                height: 10vh;
                position: absolute;
                z-index: 0;
                bottom: 0px;
                left: 0;
                background: $bannerColor;
                background: linear-gradient(180deg, $bannerColor00 0%, $bannerColor 100%);
            }
            .bannerPost {
                position: relative;
                background-repeat: no-repeat;
                background-position: right center;
                color: white;
                background-size: auto 100%;
                background: $bannerColor;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: $bannerColor;
                    background: linear-gradient(90deg, $bannerColor 40%, transparent 100%);
                }
                .data {
                    position: relative;
                    z-index: 3;
                    height: 260px;
                    display: flex;
                    align-items: center;

                    .row {
                        width: 100%;
                    }

                    .title {
                        color: white;
                        h1 {
                            margin: 0;
                            padding: 0;
                            font-size: 40px;
                            font-weight: 700;
                            letter-spacing: -1px;
                            color: #ffffff;
                        }
                    }
                    .description {
                        p {
                            margin: 0;
                            font-size: 28px;
                            color: white;
                            font-weight: 500;
                            line-height: 32px;
                        }
                    }
                }
            }
        }

        &.widget_affiliates_card_list {
            .sliderBonus {
                position: relative;
                z-index: 2;
                margin: 0px;
                padding: 0px;
                list-style: none;
                .item {
                    flex: 1 1 0px;
                    list-style: none;
                    margin: 0px;
                    padding: 0px;
                }
                .slick-disabled {
                    opacity: 0.2;
                }
                .bonusItem {
                    border-radius: 10px;
                    overflow: hidden;
                    margin-top: 10px;
                    border: 1px solid #e0e0e0;
                    background: rgba(#e0e0e0, 0.2);

                    .logo {
                        text-align: center;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: white;
                        img {
                            width: 80%;
                            object-fit: contain;
                            margin: 0px;
                            padding: 0px;
                            max-height: 80%;
                        }
                    }
                    .data {
                        width: 100%;
                        text-align: center;
                        display: inline-block !important;
                        padding: 10px 0 15px;
                        font-size: 1.2rem;
                        line-height: $fontLHeightH2;
                        font-weight: 300;
                        text-transform: uppercase;
                        .bonusText {
                            font-size: $fontSizeText;
                            font-weight: 800;
                            line-height: $fontLHeightText;
                        }
                    }
                    .action {
                        padding: 15px 20px;
                        background: white;
                        .button {
                            text-transform: uppercase;
                            padding: 0;
                            text-align: center;
                            line-height: 18px;
                            text-decoration: none;
                            background: $bonusItemBtnBg;
                            color: $bonusItemBtnText;

                            &:hover {
                                opacity: 0.8;
                                color: $bonusItemBtnText;
                            }
                            &.secundary {
                                margin-top: 10px;
                                background: $bonusItemBtnReviewBg;
                                border: 1px solid $bonusItemBtnReviewBorder;
                                color: $bonusItemBtnReviewText;
                            }
                        }
                    }
                }

                .slick-track {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    column-gap: 20px;
                }

                .arrow {
                    font-size: 30px;
                    color: $textColor;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        &.widget_contact_form {
            form {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: stretch;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                &.formContact {
                    max-width: 600px;
                }
                .formInput {
                    padding: 6px 0px;
                    display: inline-block;
                    width: 100%;
                    input,
                    textarea {
                        display: inline-block;
                        width: 100%;
                        padding: 0.8rem;
                        border: 1px solid #cbcbcb;
                        border-radius: 4px;
                    }
                }
                .action {
                    button {
                        border: 0px;
                        padding: 10px 20px;
                        border-radius: 4px;
                        font-size: $fontSizeText;
                        font-weight: 600;
                        background: $mainColor;
                        color: $mainColorHoverText;
                    }
                }
            }
        }

        &.widget_comments {
            .inputsGroup {
                width: 100%;
                margin: 0px;
            }
            h2 {
                margin: 0px;
            }
            .commentBody {
                width: 100%;
            }
            form {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: stretch;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                &.formContact {
                    max-width: 600px;
                }
                .formInput {
                    padding: 6px 0px;
                    display: inline-block;
                    width: 100%;
                    input,
                    textarea {
                        display: inline-block;
                        width: 100%;
                        padding: 0.8rem;
                        border: 1px solid #cbcbcb;
                        border-radius: 4px;
                    }
                }
                .action {
                    button {
                        border: 0px;
                        padding: 10px 20px;
                        border-radius: 4px;
                        font-size: $fontSizeText;
                        font-weight: 600;
                        background: $mainColor;
                        color: $mainColorHoverText;
                    }
                }
            }
            .listComments {
                padding-top: 2rem;
                .commetsUsers {
                    margin: 0px !important;
                    padding: 0px !important;
                    list-style: none !important;
                    .commentInfos {
                        margin: 0px !important;
                        padding: 1rem 0px 1rem 0px !important;
                        list-style: none !important;
                        border-bottom: 1px dashed #dedede;
                        h5 {
                            small {
                                font-size: $fontSizeText;
                            }
                        }
                        p {
                            margin-bottom: 0px;
                        }
                        &:last-child {
                            border-bottom: 0px;
                        }
                        .imgUser {
                            margin: 0px 1rem 0px 0px;
                            padding: 0px;
                        }
                        .userName {
                            font-size: $fontSizePostP;
                            font-weight: $fontSizePostP;
                            font-weight: 800;
                            padding-bottom: 0.5rem;
                        }
                        .dateComment {
                            font-size: $fontSizeSmallText;
                            line-height: $fontLHeightSmallText;
                            padding-bottom: 0.7rem;
                            color: #7a7a7a;
                            font-weight: 500;
                        }
                        .textComment {
                            font-size: $fontSizeText !important;
                            line-height: $fontLHeightText !important;
                            margin-bottom: 0px !important;
                        }
                        .actions {
                            .btn {
                                margin-top: 0.6rem;
                                border: 0px;
                                padding: 4px 0px;

                                color: $mainColor;
                                border-radius: 2px;
                                font-size: $fontSizeSmallText;
                                font-weight: 600;
                                display: inline-block;
                                &:hover {
                                    text-decoration: none;
                                    opacity: 0.9;
                                }
                            }
                        }
                        .media-body {
                            .commentInfos {
                                margin-top: 1rem !important;
                                padding-bottom: 0px !important;
                                border-top: 1px solid #dedede;
                            }
                        }
                    }
                }
            }
        }
    }
}

.scroll-up {
    .widget {
        .widget_affiliate {
            top: 20px;
        }
    }
}
