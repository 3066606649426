
.socialBtns{
    padding: 0px;
    display: flex;
    gap:2rem;
    a{
        text-decoration: none !important;
        &:hover{
            text-decoration: underline !important;
        }
    }
}

