@import "bootstrap/dist/css/bootstrap.min.css";
@import "slick-carousel/slick/slick";
@import "slick-carousel/slick/slick-theme";
@import "fonts.scss";
@import "variables.scss";
@import "partials/share.scss";
@import "partials/ads.scss";
@import "partials/bettingList.scss";
@import "partials/breadcrumb.scss";
@import "partials/bonus.scss";
@import "partials/bonusItem.scss";
@import "partials/categories.scss";
@import "partials/faqs.scss";
@import "partials/postCards.scss";
@import "partials/scrollProgress.scss";
@import "partials/loading.scss";
@import "article.scss";
@import "glossaries.scss";
@import "homepage.scss";
@import "events.scss";
@import "posts.scss";
@import "reviews.scss";
@import "widgets.scss";
@import "errors.scss";

html,
body {
    font-family: $mainFont;
    -webkit-font-smoothing: antialiased !important;
    font-size: 18px;
    line-height: 20px;
    background: $bgColor;
    color: $textColor;
}

body {
    overflow-x: hidden;
    &.menu-fixed {
        padding-top: 65px;
    }
}

ul {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

figcaption {
    text-align: center;
    font-size: 0.8rem;
    margin-top: -15px;
}

.notLink {
    cursor: default;
    pointer-events: none;
}

body.scroll-up header {
    transform: translateY(-110%);
}

.menu-fixed header {
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.4s ease-in-out;
    z-index: 100;
}

body.scroll-up .sidebarNav,
body.scroll-up .detailsArticle .wrapper,
body.scroll-up .glossaries .filters {
    top: 0px !important;
}

.button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: white;
    font-size: $fontSizeBtn;
    line-height: $fontLHeightBtn;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding: 0 5px;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
}

.pageCustom {
    padding-bottom: 2rem;
    .detailsArticle {
        .body {
            padding: 0px 15px;
        }
    }
    .bgListAff {
        border-radius: 10px;
        padding: 1px 1.2rem;
        position: relative;
        .label {
            position: absolute;
            left: 50px;
            top: 12px;
            padding: 4px 20px;
            background-color: #f9dc1c;
            border-radius: 4px;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            color: #000000;
            line-height: 16px;
            text-transform: uppercase;
        }
    }
    .detailsArticle.ContainerHoverTop {
        position: relative;
        z-index: 2;
    }
    .detailsArticle.ContainerHoverTop:after {
        content: "";
        width: 100%;
        height: 20vh;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
    }
    .detailsArticle.ContainerHoverTop .container {
        position: relative;
        z-index: 3;
        background: $bgColor;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 30px 40px;
        background: $bgColor;
    }
    .detailsArticle {
        .body {
            h2 {
                padding-top: 0px !important;
                margin-top: 0px !important;
                strong {
                    font-weight: 800;
                }
            }
        }
    }
}

//Last Articles
.lastArticles {
    padding-top: 100px;
    padding-bottom: 30px;
    position: relative;
    &:after {
        content: "";
        width: 100%;
        position: absolute;
        bottom: -25px;
        left: 0;
        height: 230px;
        background: rgba(#e0e0e0, 0.2);
    }
    .name {
        font-size: 20px;
        font-weight: 900;
        display: flex;
        flex-direction: column;
        text-align: right;
        color: #03102c;
        margin-top: 130px;
        position: relative;
        z-index: 1;
        .button {
            margin-top: 20px;
            display: inline-block;
            line-height: 45px;
            padding: 0 20px;
            text-transform: uppercase;
            color: $secundaryColorHoverText;
            background: $secundaryColor;
        }
        span {
            font-size: 40px;
            line-height: 40px;
        }
    }
    .posts {
        padding: 0 15px 0 70px;
    }
    .cards {
        ul {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

//Notification Alert
.notification.alert {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    text-align: center;
    border-radius: 0;
    padding: 6px;
    font-size: 14px;
    border: none;
    color: #22d172;
    background: #ecfff5;
    font-weight: bold;
}

img {
    max-width: 100%;
    &.notZoom {
        cursor: pointer;
        transform: none !important;
    }
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    max-width: 80%;
}

.navbar {
    padding: 0;
}

header nav#mainNav {
    /* overflow: hidden; */
    &.open {
        height: auto !important;
    }
}

.left-side {
    padding-left: 15.7rem;
}

.bg-gradient-banner {
    background: linear-gradient(0deg, $mainColor, #0e0e0e 186.11%);
}

.banner-img-wrapper img {
    clip-path: polygon(22% 0, 100% 0, 100% 100%, 0% 100%);
}

.wrapper-banner-info {
    margin: auto;
}

.bg-yellow {
    background-color: $secundaryColor;
}

.bg-yellow-half {
    background: linear-gradient(to right, $secundaryColor 50%, white 50%);
}

.card {
    border: none;
    border-radius: 0;
    background: transparent;
}

.card-body {
    padding-bottom: 0;
    padding-top: 0;
}

.bg-white {
    background-color: white;
}

.affiliate-box .logo {
    border-radius: 8px 8px 0px 0px;
    padding: 25px;
    border: 1px solid #e0e0e0;
}

.affiliate-box .bookmaker-cta {
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}

.affiliate-box .title {
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
}

.affiliate-box .description {
    background-color: #e8e8e8;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 5px 10px 5px;
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
}

.affiliate-box .description h6 {
    font-size: 12px;
    font-weight: bold;
    color: black;
    margin-bottom: 0;
}

.affiliate-box .description p {
    margin: 0;
    color: black;
    font-size: 26px;
    font-weight: bolder;
}

.affiliate-box .title .content {
    font-weight: bold;
    font-size: 14px;
    padding: 15px 5px 15px 5px;
}

.btn-green {
    background-color: #4fb576;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    padding: 0.375rem 1.2rem;
    line-height: 1.9;
}

.btn-green:hover {
    color: white;
    background-color: $bannerColor;
}

.title-1 span {
    font-size: 58px;
}

.title-1 {
    line-height: 2.5rem;
}

.card.card-body {
    padding-left: 15px;
    padding-right: 15px;
}

.col-md-4-custom {
    max-width: 28.54%;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #000 !important;
    background-color: #ffe22d !important;
}

.nav-link.active {
    border: 1px solid #ffe22d !important;
}

.content-mobile {
    text-align: right;
    margin-right: -4rem;
}

.features h3 {
    color: white;
    font-size: 23px;
}

.features i {
    color: #4fb576;
    margin-right: 10px;
}

.features p {
    color: #bec4d9;
    margin-bottom: 2.4rem;
    font-size: 16px;
    line-height: 24px;
}

.pt-6 {
    padding-top: 6rem;
}

.pl-6 {
    padding-left: 6rem;
}

.pb-6 {
    padding-bottom: 6rem;
}

.odds .gameInfoOthers .club {
    width: 45px;
}

.odds .gameInfoOthers {
    display: inline-flex;
    margin-top: 4px;
}

.odds .odds-box {
    text-align: center;
    padding: 5px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.odds .col.card.card-body {
    background: transparent;
}

.odds .gameResult {
    font-size: 50px;
    font-family: $mainFont;
}

.odds .teamName {
    letter-spacing: -0.2px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    padding-top: 5px;
    color: $titleColor;
}

.odds span.separator {
    font-size: 28px;
}

.odds .gameInfoOthers .result {
    padding-right: 15px;
    padding-left: 15px;
    line-height: 41px;
}

.odds .odds-box .league {
    color: #9094a2;
    font-size: 13px;
    text-transform: uppercase;
    padding-top: 10px;
    font-weight: bold;
}

.odds .odds-box .date {
    color: #9094a2;
    padding-top: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.odds .odds-box .time {
    color: #9094a2;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
}

.odds .odds-box .stream {
    color: #4fb576;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    padding-top: 15px;
}

.odds .odds-box .main-match-odds {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 20px;
}

.odds .card-body {
    padding: 5px;
}

.odds .main-match-odds span {
    padding: 7px;
    border-radius: 3px;
}

.odds .main-match-odds .odd-1,
.odds .main-match-odds .odd-2 {
    background-color: #4fb576;
    color: white;
}

.odds .main-match-odds .odd-x {
    color: white;
    background-color: $bannerColor;
}

.odds .odds-box .bookmaker {
    margin-top: 5px;
    margin-bottom: 4px;
    border-radius: 5px;
    padding: 4px;
}

.odds .odds-box .bookmaker img {
    max-height: 37px;
}

.btn-blue {
    background-color: #16336b;
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 2.2;
}

.btn-blue:hover {
    background-color: #16336b;
    color: white;
    font-weight: bold;
}

.bg-white {
    background-color: white;
}

.odds-box {
    background-color: white;
}

.navbar-toggler {
    background-color: white;
    border: white;
}

h1,
h2,
h3,
h4 {
    color: $titleColor;
}

h1 {
    font-weight: 800;
    strong {
        font-weight: 800;
    }
    font-size: $fontSizeH1;
    line-height: $fontLHeightH1;
    font-family: $secundaryFont;
}

h2 {
    font: normal normal 800 26px/33px $mainFont;
    font-size: $fontSizeH2;
    line-height: $fontLHeightH2;
    text-align: -webkit-left;
    word-break: break-word;
    margin-bottom: 1rem;
    margin-top: 3rem;
    font-weight: 700;
    color: $titleColor;
    strong {
        font-weight: 700;
    }
}

h3 {
    font-size: $fontSizeH3;
    line-height: $fontLHeightH3;
    color: $titleColor;
    strong {
        font-weight: 700;
    }
}

p {
    font-size: $fontSizeText;
    line-height: $fontLHeightText;
    font-family: $tertiaryFont;
    strong {
        font-weight: 800;
    }
}

ul {
    list-style: none;
    padding: 0;
}

.main-content {
    margin-top: 40px;
}

#mainNav {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transition: background-color 0.2s ease;
    background-color: $mainColor;
    border-bottom: 1px solid $mainColor;
    li {
        margin-right: 6px;
        a {
            display: inline-block;
        }
    }
}

#mainNav .navbar-nav .nav-item .nav-link:hover {
    text-decoration: underline;
    color: $mainColorHoverText !important;
}

#mainNav .navbar-brand {
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: black;
    img {
        max-height: 34px;
        object-fit: contain;
        max-width: 100%;
    }
}

#mainNav .navbar-nav .nav-item .nav-link {
    font-weight: 700;
    font-size: $fontSizeMenu;
    padding: 0.75rem 0;
    color: $bannerColortext;
}

#mainNav .navbar-nav .nav-item .nav-link:hover,
#mainNav .navbar-nav .nav-item .nav-link:active {
    color: $bannerColortext;
}

#mainNav .navbar-nav .nav-item .nav-link.active {
    color: $bannerColortext !important;
}

@media (min-width: 992px) {
    #mainNav {
        box-shadow: none;
    }
    #mainNav .navbar-brand {
        color: #ffffff;
    }
    #mainNav .navbar-brand:hover {
        color: black;
    }
    #mainNav .navbar-nav .nav-item .nav-link {
        padding: 0 1rem;
    }
    #mainNav .navbar-nav .nav-item .nav-link:hover {
        color: black;
    }
    #mainNav .navbar-nav .nav-item:last-child .nav-link {
        padding-right: 0;
    }
    #mainNav.navbar-shrink {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        background-color: #fff;
    }
    #mainNav.navbar-shrink .navbar-brand {
        color: #212529;
    }
    #mainNav.navbar-shrink .navbar-brand:hover {
        color: #f4623a;
    }
    #mainNav.navbar-shrink .navbar-nav .nav-item .nav-link {
        color: #212529;
    }
    #mainNav.navbar-shrink .navbar-nav .nav-item .nav-link:hover {
        color: #f4623a;
    }
    .collapse:not(.show) {
        display: none;
    }
    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }
}

.ms-auto {
    margin-left: auto !important;
}

/* HEADER  */

.hero-section {
    border-radius: 0;
    overflow: hidden;
    position: relative;
}

.hero-section p {
    color: #ffffff;
}

.bg-dark-blue {
    background-color: #001454;
}

.hero-section h1 {
    padding-top: 55px;
    text-align: left;
    font: normal normal 800 37px/38px $mainFont;
    letter-spacing: -0.74px;
    color: #ffffff;
}

.hero-section p {
    font: normal normal normal 18px/22px $tertiaryFont;
    padding-bottom: 30px;
}

hr.separator {
    border: 2px solid $secundaryColor;
}

.navbar-nav i {
    color: $secundaryColor;
}

main h3 {
    font-family: $mainFont;
    font-weight: 800;
    font-size: 17px;
    color: $titleColor;
}

.cta-btn-green {
    background: #4fb576;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: white;
    padding-right: 45px;
    padding-left: 45px;
    line-height: 27px;
}

.cta-btn-green:hover {
    color: white;
    background-color: $bannerColor;
}

/*AFFILLIATES LISTING*/

.affiliate {
    display: flex;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    column-gap: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
}

.affiliate .bookmaker-logo {
    background: #46825f;
    padding: 25px;
    width: 202px;
    text-align: center;
}

.affiliate .bookmaker-logo img {
    margin: auto;
    max-width: 152px;
    max-height: 51px;
}

.affiliate .bookmaker-pros {
    width: 465px;
    padding-left: 15px;
    padding-right: 15px;
}

.affiliate .bookmaker-pros ul li {
    font-size: 13px;
}

.affiliate .bookmaker-pros .fa-check-circle {
    color: #4fb576;
}

.affiliate .bookmaker-bonus {
    width: 400px;
}

.affiliate .bookmaker-cta {
    width: auto;
    margin: auto 0 auto auto;
    text-align: center;
    .btn-default {
        display: flex;
        flex-direction: row;
        white-space: nowrap;
    }
}

.affiliate .bookmaker-bonus .wrapper-bonus {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 1px solid $secundaryColor;
}

.affiliate .bookmaker-bonus .wrapper-bonus .bonus-value {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 50px;
    background: $secundaryColor;
}

.affiliate .bookmaker-bonus .wrapper-bonus .bonus-value .content {
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    margin: auto;
    font-weight: bold;
    line-height: 28px;
}

.affiliate .bookmaker-bonus .wrapper-bonus .bonus-type {
    margin: auto;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 21px;
}

.affiliate .bookmaker-bonus .wrapper-bonus .bonus-type span {
    font-weight: 900;
    display: block;
    font-size: 28px;
    letter-spacing: 1.4px;
}

.affiliate .bookmaker-bonus .wrapper-bonus .bonus-value span {
    display: block;
    font-weight: bolder;
    font-size: 28px;
}

.table-affiliates {
    margin-bottom: 10px;
}

footer {
    border-top: 15px solid $footerLine;
    background-color: $footerBg;
    color: $quaternaryColorHoverText;
}

footer .logoFooter {
    max-height: 20px;
    width: auto;
    max-width: 100%;
}

footer .footerGrid {
    display: flex;
    width: 100%;
    padding-bottom: 2rem;
    flex-direction: row;
}

footer h5 {
    font-weight: 600;
    font-size: 15px;
}

footer a.text-grey {
    color: $quaternaryColorHoverText;
}

footer .hasChild {
    font-weight: 700;
    margin-bottom: 2px;
    display: inline-block;
    font-size: 16px;
}

footer ul ul {
    margin-bottom: 15px;
}

footer a.text-grey:hover {
    text-decoration: underline;
}

footer ul li a {
    font-size: 14px;
}

footer .footer-bottom {
    background-color: $footerBottom;
}

.footer-bottom p {
    padding-top: 15px;
    font-size: 13px;
    color: $footerBottomText;
}

.best-bonus .label {
    text-align: right;
}

footer {
    .contactInfos {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
        .title {
            font-size: $fontSizeSmallText;
        }
        .item {
            a {
                font-size: $fontSizeSmallText;
                font-weight: 700;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.card-title {
    margin-bottom: 0.75rem;
    color: #2e2e2e;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    padding: 4px;
    margin-top: 13px;
    margin-bottom: 6rem;
    background: $secundaryColor;
}

.news-link {
    margin-bottom: 20px !important;
}

.card-text {
    color: white;
    font-size: 19px;
    font-weight: bold !important;
    padding-bottom: 0px !important;
}

/* REVIEW */

.gradient-blue {
    background-color: #031d4f;
}

.review h1 {
    font-weight: bold;
    padding-top: 5rem;
}

.post h1 {
    font-weight: bold;
    padding-top: 5rem;
    padding-bottom: 3rem;
}

.hero-card .logo {
    padding: 30px 40px 30px 40px;
    border-radius: 8px;
}

.move-overlay {
    background-color: #ffffff;
    border-radius: 15px;
    position: relative;
    -webkit-box-shadow: 0px 4px 8px -6px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 4px 8px -6px rgb(0 0 0 / 37%);
}

.cta-btn-green-big {
    background: #4fb576;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    color: white;
    padding-right: 45px;
    padding-left: 45px;
    line-height: 45px;
}

.hero-card .rating {
    margin-top: 10px;
}

.overall-rating-value-factor-ex {
    font-size: 12px;
}

.overall-rating-value-factor-ex .title {
    font-weight: 600;
}

.overall-rating-value-factor-ex * {
    display: inline;
}

.stars-layout {
    display: flex;
    justify-content: center;
}

.stars-layout > div:not(:last-child) {
    margin-right: 3px;
}

.stars-layout > div {
    width: 17px;
}

.cta-btn-green-big:hover {
    color: white;
    background-color: $bannerColor;
}

.hero-card .cta {
    margin-top: 10px;
}

h5.bonus-type {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    color: #8f9ab2;
    padding-top: 13px;
}

p.bonus-value {
    font-size: 24px;
    color: #020c2a;
    font-weight: bold;
}

.bonus-value span {
    font-size: 20px;
    display: block;
}

p.bonus-value {
    line-height: 30px;
    padding-top: 8px;
}

h5.payment-methods {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    color: #8f9ab2;
    padding-top: 27px;
}

.left-dashed {
    border-left: 1px dashed #020c2a;
}

.hero-card .pros h5 {
    font-size: 24px;
    color: #020c2a;
    font-weight: bold;
}

.hero-card .text-info {
    color: #020c2a !important;
}

.pros-cons-layout {
    padding: 15px;
}

.pros-cons-head {
    display: none;
}

.pros-cons-body > div {
    display: flex;
}

.pros-cons-body .image-layout {
    flex: 0 0 15px;
    margin-right: 10px;
    padding-top: 3px;
}

.pros {
    margin-bottom: 10px;
}

.pros-cons-body span {
    font-size: 15px;
}

nav .nav-pills .nav-link.active,
nav .nav-pills .show > .nav-link {
    color: #020b27;
    background-color: #ffe22d;
    font-weight: bolder;
    border-color: #ffe22d;
}

.nav-pills .nav-link {
    color: #909baf;
    font-weight: 900;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid #909baf;
    margin: 0 0.5%;
    line-height: 22px;
    font-size: 19px;
    padding: 20px;
}

.review .affiliates {
    margin-top: 35px;
}

.review .affiliates .affiliate-widget {
    margin-bottom: 6px;
}

.review .affiliates .affiliate-widget .logo {
    width: 170px;
    border-radius: 8px;
}

.review .affiliates .affiliate-widget .logo img {
    padding: 30px 20px 30px 20px;
}

.review .affiliates .affiliate-widget {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.review .affiliates .affiliate-widget .affiliate-name {
    font-weight: 900;
    font-size: 20px;
    margin: auto;
}

.review .affiliates .affiliate-widget .affiliate-name span {
    font-weight: 400;
    font-size: 15px;
    display: block;
    color: #9ca0aa;
}

.review .affiliates .affiliate-widget .rating {
    margin: auto;
}

.review .affiliates .affiliate-widget .stars-layout > div {
    width: 15px;
}

.review .affiliates .view-more {
    text-transform: uppercase;
    color: #6e7a88;
    font-weight: 900;
    font-size: 19px;
    border: 1px solid #bababa;
    padding: 30px;
    margin-top: 24px;
}

.review .bonuses .title {
    background: #ffe22d;
    text-align: center;
    font-weight: bold;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 4px;
}

.review .bonuses .logo {
    padding: 23px;
    border-radius: 5px;
}

.review .bonuses h5.bonus-type {
    text-transform: capitalize;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    color: #8f9ab2;
    padding-top: 11px;
}

.review .bonuses p.bonus-value {
    text-align: center;
}

.review .bonuses .title-cassino {
    background: black;
    text-align: center;
    color: white;
    font-weight: bold;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 4px;
}

.cta-btn-green-medium {
    background: #68b874;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 12px;
}

.review .affiliates .view-more:hover {
    background: black;
    color: white;
}

#sticky-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
}

#sticky-footer .logo {
    width: 230px;
    border-radius: 5px;
    padding: 20px 20px 20px 20px;
}

.fixed-bottom {
    -webkit-box-shadow: 0px 1px 13px -2px rgba(128, 128, 128, 1);
    -moz-box-shadow: 0px 1px 13px -2px rgba(128, 128, 128, 1);
    box-shadow: 0px 1px 13px -2px rgba(128, 128, 128, 1);
    bottom: -130px;
}

.fixed-bottom,
.fixed-bottom.sticky-footerbar--show {
    transition: bottom 0.25s ease-in-out, opacity 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}

section.fixed-bottom.bg-white.sticky-footerbar--show {
    bottom: 0;
}

#sticky-footer .affiliate-bonus {
    width: 380px;
}

#sticky-footer .logo img {
    padding: 0px 40px 0 40px;
}

#sticky-footer .affiliate-cta {
    margin: auto 0;
}

#sticky-footer .rating {
    margin: auto 0;
    width: 380px;
}

#sticky-footer .affiliate-bonus {
    margin: auto 0;
    line-height: 19px;
}

#sticky-footer .affiliate-bonus p {
    margin-bottom: 0;
    color: #020b27;
    font-size: 22px;
    font-weight: 900;
}

#sticky-footer .affiliate-bonus small {
    color: #939eb4;
}

#sticky-footer .affiliate-bonus h5 {
    color: #939eb4;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
}

.cta-btn-green-medium:hover {
    background-color: #16336b;
    color: white;
}

.bonus-list .list-col-left {
    padding-right: 7.5px;
}

.bonus-list .list-col-right {
    padding-left: 7.5px;
}

/* BREADCRUMBS */

.breadcrumb {
    color: #6e6e6e;
    background-color: transparent;
    font-size: 14px;
    margin-bottom: 0;
}

.breadcrumb li a {
    color: #6e6e6e;
}

/* PAYMENTS */

.payments .card-img,
.payments .card-img-bottom,
.payments .card-img-top {
    width: 70%;
    margin: 0 auto;
}

section.best-bonus {
    margin-bottom: 2rem;
}

.information ul li {
    line-height: 29px;
    font-size: 16px;
    font-family: $tertiaryFont;
    color: #6e6e6e;
    list-style: disc;
}

.information ul {
    padding: inherit;
}

.sticky-top .nav {
    background: #031e4f;
    padding: 15px !important;
    border-radius: 7px;
}

.sticky-top .nav a {
    color: white !important;
}

.sidebar a.nav-link {
    border-bottom: 1px solid #ababab;
}

.btn-blue {
    color: white;
    text-transform: uppercase;
    background-color: #0f4098;
}

.payments .box-shadow {
    padding: 25px;
    -webkit-box-shadow: 0px 4px 8px -6px rgb(0 0 0 / 37%);
    box-shadow: 0px 4px 8px -6px rgb(0 0 0 / 37%);
    border-radius: 10px;
}

nav .dropdown-menu {
    margin: 0;
    font-size: $fontSizeSubMenu;
    max-height: 340px;
    overflow-y: auto;
    background-color: $secundaryColor;
}

nav .dropdown-item {
    font-size: $fontSizeSubMenu;
    color: $secundaryColorHoverText;
}

/* NAVIVAGTION */

@media only screen and (min-width: 769px) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
    .dropdown-submenu {
        position: relative !important;
    }
    .dropdown-submenu > .dropdown-menu {
        top: 0 !important;
        left: 100% !important;
        margin-top: -6px !important;
        margin-left: -1px !important;
        border-radius: 0 !important;
    }
    .dropdown-submenu:hover > .dropdown-menu {
        display: block !important;
    }
    .dropdown-submenu > a:after {
        display: block;
        content: "f105";
        font-family: "FontAwesome";
        margin-top: -18px;
        right: 15px;
        position: absolute;
        font-weight: 300;
    }
}

/* END NAV */

.odds .gameInfoOthers .home-team,
.odds .gameInfoOthers .away-team {
    width: 80px;
    min-height: 85px;
}

.odds-box .gameInfoOthers .home-team,
.odds-box .gameInfoOthers .away-team {
    width: 80px;
    min-height: 85px;
}

.odds .gameInfoOthers .result {
    padding-right: 5px;
    padding-left: 5px;
}

h1.title-post {
    font-weight: bold;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #ffffff;
}

.review .affiliate .bookmaker-bonus .wrapper-bonus .bonus-value .content {
    line-height: 23px;
}

.review .affiliate .bookmaker-bonus {
    width: 178px;
}

.review .affiliate .bookmaker-pros {
    width: 305px;
}

.review .affiliate .bookmaker-bonus .wrapper-bonus .bonus-value span {
    font-size: 22px;
}

.review .affiliate .bookmaker-bonus .wrapper-bonus .bonus-type span {
    font-size: 22px;
}

.review .affiliate .bookmaker-bonus .wrapper-bonus .bonus-type {
    text-align: center;
}

.review .affiliate .cta-btn-green {
    padding-right: 15px;
    padding-left: 15px;
}

.review .affiliate a {
    margin: auto 0;
}

.review .affiliate .bookmaker-logo {
    text-align: center;
}

.review .affiliate .bookmaker-bonus .wrapper-bonus .bonus-value {
    width: 100%;
}

.bootstrap-select.form-control {
    width: 65% !important;
}

footer .nav-pills .nav-link,
footer .nav-pills .nav-link:hover,
footer .nav-pills .nav-link:active {
    padding: 7px;
    background: white;
    color: black;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
}

footer .nav-item.dropdown {
    width: 67%;
}

footer .dropdown-toggle::after {
    float: right;
    margin-top: 8px;
    font-size: 24px;
    margin-right: 0.255em;
}

footer .nav-pills .nav-link.active,
footer .nav-pills .show > .nav-link {
    background: white;
    color: black;
}

footer .dropdown-item:focus,
.dropdown-item:hover {
    background: $mainColor;
    color: $mainColorHoverText;
}

@media (max-width: 991px) {
    .text-center-mb {
        text-align: center;
    }
    .card.card-body {
        padding-right: 15px !important;
    }
    .review .affiliate .bookmaker-bonus .wrapper-bonus .bonus-type {
        width: 50%;
    }
    .review .affiliate .bookmaker-bonus .wrapper-bonus .bonus-value {
        width: 50%;
    }
    .review .affiliate .bookmaker-bonus {
        width: 100%;
    }
    .table-affiliates .affiliate a {
        width: 100%;
    }
    .affiliate-box .bookmaker-cta a {
        width: 100%;
    }
    .affiliate .bookmaker-bonus .wrapper-bonus .bonus-type {
        padding: 20px 0 20px 0;
    }
    .affiliate-box .logo {
        padding: 0px;
        text-align: center;
    }
    .affiliate-box .logo img {
        width: 230px;
        margin: 0 auto;
        padding: 10px;
    }
    .affiliate .bookmaker-cta {
        width: 100%;
    }
    .cta-btn-green {
        width: 100%;
    }
    .navbar-toggler {
        padding: 0.2rem 0.45rem;
    }
    .affiliate .bookmaker-logo {
        width: 100%;
        text-align: center;
    }
    .affiliate .bookmaker-bonus {
        margin-bottom: 10px;
        width: 100%;
    }
    .affiliate .bookmaker-pros {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .best-bonus .title-1 span {
        font-size: inherit;
    }
    .best-bonus .label {
        text-align: left;
    }
    .best-bonus .bg-yellow {
        background-color: unset;
    }
    .col.card.card-body.label.bg-yellow {
        background-color: white !important;
    }
    .features .pl-6 {
        padding-left: 15px;
    }
    .odds .odds-box .main-match-odds {
        display: flow-root !important;
        margin-bottom: 14px;
    }
    /* REVIEW */
    .review .sidebar {
        margin-top: 20px;
    }
    .review .bonuses .title,
    .review .bonuses .title-cassino {
        padding: 12px;
    }
    .review .affiliates .affiliate-widget .logo {
        width: 126px;
    }
    .review .affiliates .affiliate-widget .logo img {
        padding: 25px 15px 25px 15px;
    }
    .review .affiliates .view-more {
        padding: 13px;
    }
    .review h1 {
        font-size: 2rem;
        padding-top: 2rem;
    }
    .footer-bottom p {
        text-align: center;
    }
    #sticky-footer .logo {
        width: 154px;
        padding: 3px;
    }
    #sticky-footer .logo img {
        padding: 0;
    }
    #sticky-footer .rating {
        display: none;
    }
    #sticky-footer .affiliate-bonus {
        display: none;
    }
    #sticky-footer .cta-btn-green-medium {
        line-height: 30px;
    }
    .affiliate .bookmaker-bonus .wrapper-bonus .bonus-value {
        padding-right: 31px;
        padding-left: 31px;
    }
    /*PAYMENTS*/
    .payments .box-shadow {
        padding: 0;
    }
    .payment-blocks .payment {
        padding: 0;
    }
    .left-dashed {
        border-left: 0;
    }
}

.highlight-info {
    background: linear-gradient(0deg, rgba(79, 181, 118, 1), rgba(79, 181, 118, 0.6)),
        url(https://t4.ftcdn.net/jpg/01/39/71/89/240_F_139718903_5wnvxOy7veSJxXilMgaSF3W3YtJeBDw7.jpg);
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    color: white !important;
    font-size: 2.2rem !important;
}

.highlight-info .title {
    font: normal normal 800 26px/33px $mainFont;
    color: $titleColor;
    font-size: 2rem !important;
    text-align: -webkit-left;
    letter-spacing: 0.004em;
    word-break: break-word;
    margin-bottom: 1rem;
    margin-top: 3rem;
    color: white;
    text-shadow: 2px 3px $titleColor;
}

.highlight-info p {
    color: white;
    font-weight: normal;
    font-size: 1.25rem !important;
}
