.bonusItem {
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
    border: 1px solid #e0e0e0;
    background: rgba(#e0e0e0, 0.2);
    .logo {
        text-align: center;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        img {
            max-height: 80%;
            max-width: 75%;
            object-fit: contain;
        }
    }
    .data {
        width: 100%;
        text-align: center;
        display: inline-block !important;
        padding: 10px 0 15px;
        font-size: 1.2rem;
        line-height: $fontLHeightH2;
        font-weight: 300;
        text-transform: uppercase;
        .bonusText {
            font-size: $fontSizeText;
            font-weight: 800;
            line-height: $fontLHeightText;
        }
    }
    .action {
        padding: 15px 20px;
        background: white;
        .button {
            text-transform: uppercase;
            padding: 0;
            text-align: center;
            line-height: 18px;
            background: $bonusItemBtnBg;
            color: $bonusItemBtnText;

            &:hover {
                opacity: 0.8;
                color: $bonusItemBtnText;
            }
            &.secundary {
                margin-top: 10px;
                background: $bonusItemBtnReviewBg;
                border: 1px solid $bonusItemBtnReviewBorder;
                color: $bonusItemBtnReviewText;
            }
        }
    }
}
