.errorspage {
    .bannerSectionErrors {
        color: white;
        height: 70vh;
        min-height: 500px;
        background-size: cover;
        background-position: center center;
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        background-color: $bannerColor;
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            opacity: 0.8;
            background: linear-gradient(220deg, $bannerColor00 50%, $bannerColor 100%);
            background: $bannerColor;
        }
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            opacity: 0.8;
            background: $bannerColor;
            background: linear-gradient(-50deg, $bannerColor 0%, $bannerColor00 50%);
        }
        &:before {
            z-index: 1;
        }
        .container {
            position: relative;
            z-index: 11;
        }
        .title {
            position: relative;
            z-index: 3;
            h1 {
                font-size: $fontHomeSizeH1;
                line-height: $fontHomeLHeightH1;
                padding-bottom: 2rem;
                color: $secundaryColor;
            }
        }
        .description {
            position: relative;
            z-index: 3;
            margin-top: 2rem;
            font-size: $fontSizeH3;
            line-height: $fontLHeightH3;
            max-width: 80%;
            text-align: center;
            display: inline-block;
            color: $bannerColortext;
        }
        .actions {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            flex-direction: row;
            column-gap: 20px;
            padding-top: 2rem;
            .btn {
                display: inline-block;
                padding: 0.5rem 2rem;
                border-radius: 4px;
                text-transform: uppercase;
                font-weight: 600;
                &.btnHome {
                    background: $secundaryColor;
                    color: $secundaryColorHoverText;
                }
                &.btnBack {
                    background: $mainColor;
                    color: $mainColorHoverText;
                }
                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
    .listBetAff {
        padding: 2rem;
    }
    &.clean {
        .bannerSectionErrors {
            height: 100vh;
        }
    }
}
