.bonusSection {
    &.theme1 {
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        display: grid;
        grid-template-columns: 18% 35% 1fr;
        column-gap: 40px;
        align-items: center;
        .logo {
            height: 100%;
            background: #ccc;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-height: 80%;
                max-width: 80%;
            }
        }
        .specs {
            .title {
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 10px;
            }
            li {
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 5px;
                display: flex;
                list-style: none;
                i {
                    color: #4fb576;
                    margin-right: 8px;
                }
                &.cons {
                    i {
                        color: #ff6e6e;
                    }
                }
            }
        }

        .action {
            display: flex;
            justify-content: space-between;
            column-gap: 40px;
            flex-direction: row;
            .block {
                flex: 1 1 0;
                display: flex;
                height: 110px;
                align-items: center;
                border: 1px solid $secundaryColor;

                .key,
                .value {
                    flex: 1 1 0px;
                    text-align: center;
                    padding: 10px;
                }
                .key {
                    font-size: 20px;
                    text-transform: uppercase;
                }
                .value {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 800;
                    padding: 0 15px;
                    line-height: 22px;
                    background: $secundaryColor;
                    color: $secundaryColorHoverText;
                }
            }
            .click {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .button {
                    margin: 5px 0;
                    width: 100%;
                    padding: 0 20px;
                    text-transform: uppercase;
                    &.secundary {
                        background: #eee !important;
                        font-weight: 400;
                        font-size: 14px;
                        font-weight: 700;
                        color: black;
                        text-transform: uppercase;
                        &:hover {
                            color: black;
                        }
                    }
                }
            }
            .buttonsAction {
                .button {
                    background: $mainColor;
                }
            }
        }
    }
    &.theme2 {
        margin: 1.2rem 0;
        padding: 14px;
        border-radius: 10px;
        border: 1px solid #efefef;
        display: grid;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        grid-template-columns: 0.6fr 1fr 1fr 1fr;
        box-shadow: 0px 5px 5px rgb(114 114 114 / 10%);
        background: $widgetBg;
        color: $widgetText;

        &.hasCat {
            grid-template-columns: 0.6fr 0.6fr 1fr 1fr 0.9fr;
        }

        .bookmark {
            padding-right: 15px;
            border-right: 1px solid #e1e1e1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: nowrap;
            align-content: center;
            .logo {
                height: auto;
                background: transparent;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2% 0px;
                border-radius: 6px;
                height: 90px;
                img {
                    width: 90%;
                    object-fit: contain;
                    margin: 0px;
                    padding: 0px;
                    max-height: 90%;
                    height: auto;
                }
                &:hover {
                    opacity: 0.9;
                }
            }
            .starts {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                align-content: center;
                justify-content: center;
                ul {
                    display: flex;
                    border: 2px solid #f1f1f1;
                    border-radius: 5px;
                    padding: 0px 0px 0px 15px;
                    margin: 0px 0px 0px -15px;
                    z-index: 1;
                    padding-right: 5px;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    list-style: none;
                    li {
                        margin: 0 2px;
                        line-height: 22px;
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                        i {
                            font-size: 14px;
                            line-height: 14px;
                            color: $secundaryColor;
                        }
                    }
                }
                .description {
                    font-weight: 600;
                    font-size: $fontSizeSmallText;
                    border-radius: 100px;
                    width: 38px;
                    height: 38px;
                    min-width: 38px;
                    min-height: 38px;
                    text-align: center;
                    line-height: 32px;
                    border: 3px solid #ffffff;
                    position: relative;
                    z-index: 5;
                    background: $secundaryColor;
                    color: $secundaryColorHoverText;
                }
            }
        }

        .specs {
            padding: 0px 15px;
            border-right: 1px solid #e1e1e1;
            height: 100%;
            display: flex;
            align-content: center;
            align-items: center;
            color: $widgetText;

            .infos {
                h2 {
                    text-align: left;
                    margin: 0px;
                    padding: 0px;
                    padding-bottom: 6px;
                    font-size: $fontSizeH3;
                    line-height: $fontLHeightH3;
                    color: $widgetText;
                }
                .title {
                    font-size: 20px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
                ul.listSpecs {
                    margin: 0px;
                    padding: 0px;
                    list-style: none;
                    li {
                        font-size: $fontSizeText;
                        line-height: $fontLHeightText;
                        margin: 0px 0px 2px 0px;
                        padding: 0px;
                        display: flex;
                        list-style: none;
                        i {
                            color: #4fb576;
                            margin-right: 8px;
                            line-height: $fontLHeightText;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .catList {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            height: 100%;
            padding: 0px 15px;
            border-right: 1px solid #e1e1e1;
            h2 {
                display: flex;
                margin: 0px;
                padding-bottom: 4px;
                font-size: $fontSizeH2;
                line-height: $fontLHeightH2;
            }
            .iconsList {
                display: flex;
                ul {
                    display: flex;
                    column-gap: 5px;
                    li {
                        .labelIcon {
                            background: #eeeeee;
                            display: inline-block;
                            border-radius: 4px;
                            font-size: $fontSizeText;
                            line-height: $fontLHeightText;
                            font-weight: 600;
                            padding: 2px 10px;
                        }
                    }
                }
            }
        }
        .payments {
            display: flex;
            padding: 0px 15px;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            height: 100%;
            .title {
                font-size: $fontSizeBtn;
                font-weight: 600;
                line-height: $fontLHeightBtn;
                padding-bottom: 10px;
                text-align: center;
            }
            .listPayments {
                display: flex;
                position: relative;

                &:hover {
                    ul.drop {
                        display: grid;
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                        align-content: center;
                        align-items: center;
                        position: absolute;
                        top: calc(100% + 0px);
                        background: #ffffff;
                        box-shadow: 0px 10px 10px rgba(114, 114, 114, 0.2);
                        padding: 10px;
                        border-radius: 5px;
                        z-index: 100;
                        width: 70%;
                    }

                    img {
                        -webkit-filter: none;
                        filter: none;
                        opacity: 1;
                    }
                }

                ul {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    gap: 10px;
                    align-content: center;
                    align-items: center;
                    padding: 0px;
                    margin: 0px;
                    &.drop {
                        display: none;
                    }
                    li {
                        order: 1;
                        text-align: center;
                        margin: 0px 0px 8px 0px;
                        padding: 0px;
                        list-style: none;
                        background: #f2f2f2;
                        margin: 0;
                        padding: 10px;
                        border-radius: 5px;
                        &.more {
                            order: 1000;
                            .btnMorePayments {
                                background: #f2f2f2;
                                display: inline-block;
                                border-radius: 5px;
                                font-size: $fontSizeText;
                                line-height: $fontLHeightText;
                                font-weight: 600;
                                padding: 0px 10px;
                            }
                        }
                        &.hide {
                            display: none;
                        }
                        .logoItem {
                            display: inline-block;
                            &.isHighlight {
                                padding: 4px;
                                border-radius: 4px;
                                margin-bottom: 5px;
                                &.withBg {
                                    img {
                                        padding: 0px;
                                        filter: brightness(0) invert(1) !important;
                                    }
                                }
                                img {
                                    padding: 0px;
                                    object-fit: contain;
                                    width: 90%;
                                    max-height: 26px;
                                    max-width: 90%;
                                }
                            }
                        }
                        img {
                            max-height: 26px;
                            max-width: 90%;
                            display: inline-block;
                            -o-object-fit: contain;
                            object-fit: contain;
                            -webkit-filter: grayscale(1);
                            filter: grayscale(1);
                            opacity: 1;
                            padding: 0px 0px 5px 0px;
                            margin: 0px;
                            &.isHighlight {
                                -webkit-filter: none;
                                filter: none;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .action {
            display: flex;
            justify-content: space-between;
            column-gap: 15px;
            flex-direction: row;
            .block {
                flex: 1 1 0;
                border: 0px;
                display: flex;
                height: auto;
                border-radius: 5px;
                padding: 15px;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                flex-wrap: nowrap;
                align-items: center;
                background: $secundaryColor;
                color: $secundaryColorHoverText;

                .key {
                    display: block;
                    font-size: $fontSizeH2;
                    line-height: $fontLHeightH2;
                    padding-bottom: 5px;
                    font-weight: 300;
                    text-transform: uppercase;
                }
                .value {
                    display: flex;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    font-size: $fontSizeH3;
                    font-weight: 800;
                    line-height: $fontLHeightH3;
                    text-align: center;
                    padding: 0px;
                }
            }
            .click {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .button {
                    margin: 5px 0;
                    width: 100%;
                    padding: 0 20px;
                    text-transform: uppercase;
                    &.secundary {
                        font-weight: 400;
                        font-weight: 700;
                        color: $mainColor;
                        text-transform: uppercase;
                        background: $bonusItemBtnReviewBg;
                        border: 1px solid $bonusItemBtnReviewBorder;
                        color: $bonusItemBtnReviewText;

                        &:hover {
                            opacity: 0.8;
                            color: $bonusItemBtnReviewText;
                        }
                    }
                }
            }
            .buttonsAction {
                .button {
                    text-decoration: none;
                    background: $bonusItemBtnBg;
                    color: $bonusItemBtnText;

                    &:hover {
                        opacity: 0.8;
                        color: $bonusItemBtnText;
                    }
                }
            }
        }

        &.widgetAffiliateList {
            grid-template-columns: 0.6fr 1fr;
            margin-bottom: 1.5rem;
            .bookmark {
                .logo {
                    margin-bottom: 10px;
                    img {
                        margin: 0px;
                        padding: 5% 0px;
                    }
                }
                .block {
                    border: 0px;
                    display: flex;
                    height: auto;
                    border-radius: 5px;
                    padding: 2%;
                    flex-direction: row;
                    align-content: center;
                    justify-content: center;
                    flex-wrap: nowrap;
                    align-items: center;
                    background: $secundaryColor;
                    color: $secundaryColorHoverText;

                    .key {
                        display: flex;
                        font-size: $fontSizeH2;
                        line-height: $fontLHeightH2;
                        padding-bottom: 0px;
                        font-weight: 300;
                        text-transform: uppercase;
                        flex-direction: column;
                        align-content: stretch;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: nowrap;
                        flex: 1;
                    }
                    .value {
                        display: flex;
                        flex-wrap: nowrap;
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
                        font-size: $fontSizeH3;
                        font-weight: 800;
                        line-height: $fontLHeightH3;
                        text-align: center;
                        padding: 0px;
                        flex: 1;
                    }
                }
            }
            .specs {
                border: 0px;
                align-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
                flex-wrap: nowrap;
                .infos {
                    width: 100%;
                    ul.listSpecs {
                        width: 100%;
                        padding: 0px;
                        margin: 0px;
                        li {
                            margin-left: 0px;
                            padding: 0px;
                        }
                    }
                }

                .buttonsAction {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    column-gap: 1rem;
                    padding-top: 0.5rem;
                    .button {
                        display: flex;
                        flex: 1;
                        margin: 5px 0;
                        width: 100%;
                        padding: 0 20px;
                        text-transform: uppercase;
                        text-decoration: none;
                        background: $bonusItemBtnBg;
                        color: $bonusItemBtnText;

                        &:hover {
                            opacity: 0.9;
                            color: $bonusItemBtnText;
                        }
                        &.secundary {
                            font-weight: 400;
                            font-weight: 700;
                            color: $mainColor;
                            text-transform: uppercase;
                            background: $bonusItemBtnReviewBg;
                            border: 1px solid $bonusItemBtnReviewBorder;
                            color: $bonusItemBtnReviewText;

                            &:hover {
                                opacity: 0.9;
                                color: $bonusItemBtnReviewText;
                            }
                        }
                    }
                }
            }
        }
    }
}
