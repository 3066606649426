.oddItem {
    background: white;
    border-radius: 10px;
    padding: 10px 10px;
    color: black;
    text-align: center;
    font-size: 12px;
    margin: 0 10px;
    .league {
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 20px;
            margin-right: 5px;
        }
    }
    .date {
        display: flex;
        margin: 5px 0 0;
        flex-direction: column;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: -25px;
    }
    .data {
        display: flex;
        flex-wrap: wrap;
        height: 100px;
        align-items: center;
        .home,
        .away,
        .draw {
            flex: 1 1 0px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            line-height: 14px;
            .img {
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
                img {
                    height: 55px;
                }
            }
        }
        .draw {
            font-size: 22px;
            font-weight: 700;
        }
    }
    .odd {
        display: flex;
        flex-wrap: wrap;
        column-gap: 5px;
        margin: 5px 0;
        .oddValue {
            font-size: 16px;
            font-weight: bold;
            border-radius: 5px;
            flex: 1 1 0px;
            padding: 5px 0;
            height: 45px;
            align-items: center;
            display: flex;
            justify-content: center;
            background: $secundaryColor;
            &.draw {
                color: white;
                background-color: $mainColor;
            }
        }
    }
    .bet {
        border-radius: 5px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-height: 60%;
        }
    }
    .button {
        margin: 5px 0 0;
        text-transform: uppercase;
        background: $mainColor;
        &:hover {
            color: white;
            opacity: 0.8;
        }
    }
}

.odds {
    .banner {
        /*  min-height: 600px; */
        background-image: url("/images/bgOdds.webp");
        background-position: top center;
        background-size: cover;
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 2rem;
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            opacity: 0.8;
            background: $bannerColor;
            background: linear-gradient(90deg, $bannerColor 0%, $bannerColor 15%, $bannerColor00 50%, $bannerColor 85%, $bannerColor 100%);
        }
        &:after {
            content: "";
            width: 100%;
            height: 50%;
            background: $bannerColor;
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: 0;
            opacity: 0.8;
            background: $bannerColor;
            background: linear-gradient(180deg, $bannerColor00 0%, $bannerColor 100%);
        }
        .titlePage {
            position: relative;
            z-index: 3;
            line-height: 70px;
            margin: 20px 0;
            width: 100%;
            display: inline-block;
            h1 {
                font-size: 60px;
                font-weight: 900;
                letter-spacing: -1px;
                line-height: 65px;
                margin-top: 20px;
                margin-bottom: 0;
                color: white;
                strong {
                    color: $secundaryColor;
                }
                em {
                    font-size: 40px;
                    font-weight: 800;
                    line-height: 40px;
                    font-style: normal;
                }
            }
        }
        .slider {
            width: 80%;
            margin: auto;
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            .arrow {
                font-size: 40px;
                color: white;
                &:before {
                    display: none;
                }
                &.slick-prev {
                    left: -50px;
                }
                &.slick-next {
                    right: -50px;
                }
            }
        }
    }

    .descriptionSection {
        margin-top: 30px;
        padding-bottom: 50px;
        .block {
            padding-top: 30px;
            border-top: 4px solid $secundaryColor;

            p {
                font-size: $fontSizePostP;
                line-height: $fontLHeightPostP;
            }
        }
    }

    .listData {
        margin-bottom: 30px;
        .name {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            font-size: 18px;
            font-weight: bold;
            align-items: center;
            margin-bottom: 15px;
            border-bottom: 4px solid $secundaryColor;
            color: $quaternaryColor;

            .barMenuSelect {
                display: flex;
                width: 100%;
            }
            span {
                font-weight: 400;
                font-size: 16px;
            }
            i {
                top: -3px;
                display: inline-block;
                position: relative;
                font-size: 20px;
                margin-left: 5px;

                color: $secundaryColor;
            }
            .total {
                font-size: 14px;
                color: $mainColor;
            }
        }
        ul {
            li {
                margin-bottom: 7px;
                column-gap: 10px;
                position: relative;
                color: $quaternaryColor;

                .link {
                    display: grid;
                    grid-template-columns: 20% 1fr;
                    border-radius: 10px;
                    min-height: 80px;
                    background: rgba(#e0e0e0, 0.2);
                    column-gap: 15px;
                    .highlight {
                        font-size: 12px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        line-height: 14px;
                        align-items: center;
                        text-transform: capitalize;
                        .image {
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                            background-position: center;
                            background-size: cover;
                            position: relative;
                            overflow: hidden;
                        }
                    }
                    .label {
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: $mainColor;
                        font-size: 12px;
                        font-weight: bold;
                        padding: 4px 12px;
                        line-height: 14px;

                        background: $secundaryColor;
                    }
                    .data {
                        display: flex;
                        flex-direction: column;
                        font-size: 13px;
                        line-height: 18px;
                        padding-right: 15px;
                        justify-content: center;
                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                            vertical-align: middle;
                            text-transform: capitalize;
                        }
                        b {
                            font-size: 14px;
                            line-height: 16px;
                            font-weight: 600;
                            margin-bottom: 2px;
                        }
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
        .empty {
            width: 100%;
            padding: 200px 0;
            font-size: 14px;
            text-align: center;
        }
        &.news {
            ul li a {
                grid-template-columns: 30% 1fr;
                .highlight {
                    .image {
                        &:after {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: rgb(16, 39, 90);
                            background: linear-gradient(180deg, rgba(16, 39, 90, 0.47) 0%, rgba(4, 20, 52, 1) 100%);
                            opacity: 0.4;
                            z-index: 1;
                        }
                    }
                }
            }
        }
        &.betting {
            ul li a {
                grid-template-columns: 30% 1fr;
                .highlight {
                    .image {
                        display: flex;
                        border-radius: 0;
                        justify-content: center;
                        height: 70%;
                        width: 90%;
                        margin-left: 10px;
                        background: white;
                        img {
                            max-width: 80%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
    .event {
        width: 100% !important;
        min-height: 100px !important;
        display: grid !important;
        box-shadow: 0 3px 6px rgba(black, 0.15);
        grid-template-columns: 70px 1fr 70px !important;
        margin-bottom: 15px;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 10px 15px;
        background: white !important;
        font-size: 14px;
        &:hover {
            cursor: pointer;
            background: rgba(#e0e0e0, 0.2) !important;
        }
        .date {
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 18px;
            text-transform: uppercase;
            width: 100px;
            .previsionBet {
                text-transform: uppercase;
                font-size: 0.7rem;
                margin-top: 10px;
                margin-bottom: -10px;
                i {
                    margin-right: 5px;
                    color: $secundaryColor;
                }
            }
        }
        .data {
            width: 100%;
            display: grid !important;
            grid-template-columns: 1fr 120px 1fr;
            padding-right: 0 !important;
            text-align: center;
            .home {
                text-align: right;
                .team {
                    justify-content: end;
                }
            }
            .away {
                text-align: left;
            }
            .team {
                font-size: 12px;
                line-height: 14px;
                font-weight: 600;
                text-transform: uppercase;
                display: inline-block;
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                .img {
                    min-width: 60px;
                    text-align: center;
                    img,
                    svg {
                        height: 40px;
                        object-fit: contain;
                    }
                }
            }
            .result {
                font-size: 20px;
                font-weight: 700;
                line-height: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 40px;
            }
            .oddValue {
                display: inline-block;
                color: $mainColor;
                font-size: 12px;
                font-weight: bold;
                padding: 4px 10px;
                line-height: 14px;
                margin-top: 15px;
                min-width: 60px;
                text-align: center;
                background: $secundaryColor;
            }
            .postponed {
                margin-top: 15px;
                display: inline-block;
            }
        }
        .bet {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            display: flex;
            justify-content: end;
            a {
                img {
                    object-fit: contain;
                    height: 30px;
                }
            }
        }
        .gameResult {
            width: 26px;
            height: 26px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: 700;
            font-size: 16px;
            background: #14dc4b;
            text-align: right;
            text-transform: uppercase;
            &.type_d {
                background: #dc0000;
            }
            &.type_e {
                background: #535353;
            }
        }
    }

    .wrapper {
        .sidebar.left {
            padding-right: 30px;
        }
        .sidebar.right {
            padding-left: 30px;
        }
        .data {
            .group {
                margin-bottom: 30px;
                .header {
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                    color: black;
                    justify-content: space-between;
                    white-space: nowrap;
                    img {
                        height: 20px;
                        margin-right: 5px;
                        float: left;
                    }
                }
            }
        }
    }
    &.details {
        padding-bottom: 50px;
        .contentInfos {
            padding: 0;
            padding-top: 20px;
            margin-bottom: 20px;
            h2 {
                font-size: 16px;
                line-height: 24px;
                b {
                    font-size: 30px;
                }
                margin: 0;
                font-weight: 400;
            }
        }
        .body {
            padding: 20px 15px 50px;
        }
        .dataTeams {
            .banner {
                color: white;
                margin-bottom: 0;
                min-height: 450px;
                &.hasPrognostic {
                    min-height: 580px;
                }
                .container {
                    position: relative;
                    z-index: 9;
                }
                .header {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 50px;
                    font-size: 22px;
                    text-transform: uppercase;
                    font-weight: bold;
                    align-items: center;
                    img {
                        height: 15px;
                        margin-top: -2px;
                    }
                    .titleGame {
                        h1 {
                            display: block;
                            color: $bannerColortext;

                            span {
                                font-weight: 400;
                            }
                        }
                        .titleLeague {
                            font-size: $fontSizeText;
                            line-height: $fontLHeightText;
                        }
                    }

                    .dateGame {
                        font-size: $fontSizeText;
                        line-height: $fontLHeightText;
                        text-align: right;
                    }
                }
                .teams {
                    margin-top: 20px;
                    display: grid;
                    grid-template-columns: 1fr 60% 1fr;
                    column-gap: 60px;
                    .specs {
                        text-transform: uppercase;
                        padding-top: 50px;
                        font-size: 14px;
                        font-weight: 600;
                        .name {
                            margin-bottom: 10px;
                            color: $secundaryColor;
                        }
                        ul {
                            li {
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 10px;
                                align-items: center;
                                .formTeam {
                                    display: flex;
                                    column-gap: 5px;
                                    li {
                                        background: gray;
                                        border-radius: 5px;
                                        height: 25px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 20px;
                                        font-size: 11px;
                                        margin-bottom: 0;
                                        &.type_W {
                                            background: #32b760;
                                        }
                                        &.type_L {
                                            background: #ff0034;
                                        }
                                        &.type_D {
                                            background: #ffc900;
                                            color: black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .data {
                        .highlight {
                            display: grid;
                            grid-template-columns: 1fr 50% 1fr;
                            padding-bottom: 30px;
                            margin-bottom: 30px;
                            align-items: start;
                            border-bottom: 4px solid $secundaryColor;

                            .team {
                                text-transform: uppercase;
                                justify-content: flex-start;
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                font-size: 18px;
                                font-weight: 700;
                                s img,
                                svg {
                                    height: 100px;
                                    margin-bottom: 20px;
                                    width: auto;
                                    object-fit: contain;
                                }
                            }
                            .draw {
                                height: 100%;
                                justify-content: center;
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                align-items: center;
                                flex-wrap: nowrap;
                                .dateGame {
                                    text-transform: capitalize;
                                    .date {
                                        font-size: 22px;
                                        text-transform: uppercase;
                                        font-weight: bold;
                                        margin-bottom: 30px;
                                    }
                                }
                                .result {
                                    font-size: 60px;
                                    font-weight: 700;
                                }
                            }
                        }
                        .odds {
                            grid-template-columns: 1fr 50% 1fr;
                            display: grid;
                            font-size: 28px;
                            font-weight: 700;
                            .item {
                                display: flex;
                                justify-content: center;
                                text-transform: uppercase;
                                span {
                                    margin-right: 15px;
                                    opacity: 0.5;
                                    font-size: 22px;
                                }
                                i {
                                    margin-left: 15px;
                                    font-size: 32px;
                                    margin-top: -5px;
                                    &[class$="down"] {
                                        color: #dc0000;
                                    }
                                    &[class$="up"] {
                                        color: #14dc4b;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .oddsText {
                width: 100%;
                margin: -120px auto auto;
                background: white;
                padding: 20px 30px 0;
                background: white !important;
                position: relative;
                border-radius: 10px;
                z-index: 9;
                h2 {
                    margin-top: 0;
                }
                p {
                    font-size: $fontSizePostP;
                    line-height: $fontLHeightPostP;
                }
            }
            .history {
                width: 65%;
                position: relative;
                margin: 50px auto 0;
                .nav {
                    border-bottom: 4px solid var(--styleColorsSecondary);
                    padding-bottom: 0;
                    margin-bottom: 20px;
                    justify-content: center;
                    li {
                        margin-right: 2px;
                        margin-bottom: 0px;
                        flex: 1;
                        text-align: center;
                        &:nth-child(1) {
                            text-align: right;
                        }
                        &:nth-child(3) {
                            text-align: left;
                        }
                        a {
                            display: inline-block;
                            padding: 0;
                            border: none;
                            font-size: 16px;
                            font-weight: bold;
                            white-space: nowrap;
                            background: transparent;
                            margin: 0;
                            border-radius: 0;
                            opacity: 1;
                            min-height: auto;
                            padding: 14px 18px;
                            &.active {
                                border: none !important;
                                border-top-left-radius: 5px;
                                border-top-right-radius: 5px;
                                background-color: $secundaryColor !important;
                                color: $secundaryColorHoverText !important;
                            }
                        }
                    }
                }
            }
        }
        .contentBody {
            width: 65%;
            margin: auto;
        }
        .suggestions {
            padding: 50px 0;
            color: white;
            background: $mainColor;

            .data {
                display: grid;
                grid-template-columns: 1fr 50% 1fr;
                .name {
                    font-size: 32px;
                    line-height: 34px;
                    b {
                        width: 100%;
                        font-size: 38px;
                        display: inline-block;
                        font-weight: 700;
                    }
                }
                ul {
                    display: flex;
                    justify-content: space-around;
                }
            }
        }
    }
}
