.detailsArticle {
    .wrapper {
        position: sticky;
        top: 66px;
        transition: all 0.4s ease-in-out;
    } /* 
    .author{
        background: #F0F0F0;
        padding: 15px 20px;
        color: #03102C;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        .image{
            height: 35px;
            width: 35px;
            border-radius: 50%;
            margin-right: 10px;
            margin-top: 0px;
            background-size: cover;
            background-position: center;
        }
        ul{
            li{
                line-height: 18px;
                margin-bottom: 10px;
                font-size: $fontSizeSmallText;
                .value{
                    font-size: $fontSizeText;
                    font-weight: 700;
                }
            }
        }
        .ads{
            .button{
                background: rgb(var(--reviewMainColor));
            }
        }
    } */
    &.pageCustom {
        .body {
            padding: 0px 15px;
        }
    }
    .body {
        padding: 0 30px 100px 30px;
        .author {
            width: 100%;
            display: inline-block;
            margin-top: 1rem;
            .description {
                ul {
                    display: flex;
                    justify-content: space-between;
                    padding: 0;
                    li {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        display: flex;
                        font-size: 0.8rem;
                        column-gap: 5px;
                        color: #7a7a7a;
                    }
                }
            }
        }
        .contentBody > h2:first-child {
            margin-top: 0px;
        }
        .contentBody {
            a {
                text-decoration: underline;
                color: $urlColor;
            }
            img {
                position: relative;
                transition: all 0.2s ease-in-out;
                &:hover {
                    cursor: pointer;
                    transform: scale(1.05);
                }
            }
        }
        .categoryContent {
            display: inline-block;
            vertical-align: middle;
            .cat {
                margin-top: 1.5rem;
                text-transform: uppercase;
                display: inline-block;
                padding: 5px 10px 4px 10px;
                font-size: $fontSizeBtn;
                border-radius: 2px;
                font-weight: 600;
                vertical-align: middle;
                color: $secundaryColorHoverText;
                background: $secundaryColor;
                .image {
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background: #f7e144;
                    margin-top: 0px;
                    vertical-align: middle;
                }
            }
        }

        h1 {
            margin: 20px 0 20px;
            font-weight: 800;
            padding: 0;
            color: $titleColor;
        }

        h2 {
            margin: 0px;
            margin-top: 1.5rem;
            padding: 1rem 0 1rem 0;
        }

        h3 {
            font-weight: 700;
            margin: 30px 0px 10px;
        }

        p {
            font-size: $fontSizePostP;
            line-height: $fontLHeightPostP;
        }
        ul {
            padding-bottom: 1.5rem;
            padding-left: 1rem;
            li {
                list-style: disc;
                margin-left: 15px;
                padding: 5px 0px;
            }
        }
        img {
            max-width: 100%;
            height: auto;
            margin: 1.5rem 0px;
        }

        .contentInfos {
            display: block;
            width: 100%;
            padding-bottom: 2rem;
            .datePost {
                float: right;
                vertical-align: middle;
                display: inline-block;
                font-size: $fontSizeSmallText;
                line-height: 46px;
                color: #7a7a7a;
                padding-top: 1.7rem;
            }
        }

        ul.numlistCustom {
            padding: 2rem 0px !important;
            list-style: none;
            padding: 0px;
            margin: 0px;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            padding: 0;
            li {
                padding: 0px;
                margin: 0px;
                display: flex;
                flex-direction: column;
                list-style: none;
                position: relative;
                counter-increment: dotted-counter;
                justify-content: center;
                align-items: flex-start;
                padding-left: 2.3rem;
                strong {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 26px;
                    padding-bottom: 5px;
                    color: $titleColor;
                }
                &:before {
                    font-size: 15px;
                    line-height: 29px;
                    border-radius: 100px;
                    text-align: center;
                    margin-right: 5px;
                    width: 28px;
                    height: 28px;
                    display: inline-block;
                    vertical-align: middle;
                    position: absolute;
                    z-index: 2;
                    left: 0px;
                    top: 0px;
                    border-radius: 50%;
                    content: counter(dotted-counter);
                    font-weight: 700;
                    color: $secundaryColorHoverText;
                    background: $secundaryColor;
                }
            }
        }
    }

    .sideBarInfos {
        transition: all 0.4s ease-in-out;
        .sidebarNav {
            border-top: none;
            padding: 0px;
            position: -webkit-sticky;
            position: sticky;
            top: 66px;
            overflow: hidden;
            overflow-y: auto;
            max-height: 100vh;
            transition: all 0.4s ease-in-out;
            ul {
                li {
                    margin: 4px 0px;
                    display: flex;
                    align-items: center;

                    a {
                        display: block;
                        width: 100%;
                        padding: 12px 15px;
                        background: #f0f0f0;
                        color: #03102c;
                        border-radius: 4px;
                        font-size: $fontSizeSmallText;
                        line-height: 16px;
                        font-weight: 400;
                        &:hover {
                            cursor: pointer;
                            background: rgba(#e8e8e8, 0.8);
                        }
                        &.active {
                            font-weight: 600;
                            background: $secundaryColor;
                            color: $secundaryColorHoverText;
                        }
                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            line-clamp: 1;
                            -webkit-box-orient: vertical;
                            vertical-align: middle;
                        }
                    }

                    &:last-child {
                        border-bottom: 0px;
                    }
                }
            }
        }
    }
}
