.glossaries {
    .bannerPost {
        position: relative;
        background-repeat: no-repeat;
        background-position: right center;
        color: white;
        background-size: auto 100%;
        background: $bannerColor;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: $titleColor;
            background: linear-gradient(90deg, $bannerColor 0%, $bannerColor 30%, $bannerColor 50%, $bannerColor00 85%, $bannerColor00 100%);
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
            opacity: 0.5;
            z-index: 2;
            background: $bannerColor;
        }
        .data {
            position: relative;
            z-index: 3;
            height: 260px;
            display: flex;
            align-items: center;
            .row {
                width: 100%;
            }
            .title {
                color: white;
                h1 {
                    margin: 0;
                    padding: 0;
                    font-size: 40px;
                    font-weight: 700;
                    letter-spacing: -1px;
                    color: #ffffff;
                }
            }
            .description {
                p {
                    margin: 0;
                    font-size: 28px;
                    color: white;
                    font-weight: 500;
                    line-height: 32px;
                }
            }
        }
    }
    .subtitle {
        font-size: 16px;
        display: inline-block;
        width: 100%;
        h2 {
            margin: 0;
            font-size: 30px;
            font-weight: 900;
            margin-bottom: 10px;
        }
    }
    .filters {
        transition: all 0.4s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: sticky;
        background-color: white;
        padding: 10px 0;
        top: 66px;
        z-index: 9;
        .search {
            position: relative;
            flex: 1 1 0px;
            padding-right: 15px;
            .icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
                i {
                    font-size: 18px;
                }
            }
            input {
                width: 100%;
                background: #f9f9fa;
                border-radius: 5px;
                border: 1px solid #e8e8e8;
                padding-left: 45px;
                height: 40px;
            }
        }
        .alphas {
            flex: 2 1 0px;
            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                column-gap: 5px;
                li {
                    flex: 1 1 0px;
                    font-size: 18px;
                    font-weight: 500;
                    border: 1px solid #e8e8e8;
                    border-radius: 5px;
                    background: #f9f9fa;
                    flex-wrap: wrap;
                    color: $quaternaryColor;

                    a {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &.active {
                        color: white;
                        background: $secundaryColor;
                    }
                    &:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    .data {
        .group {
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
            .letter {
                height: 40px;
                font-size: 18px;
                font-weight: 600;
                color: white;
                border-radius: 5px;
                display: flex;
                align-items: center;
                width: 35px;
                justify-content: center;
                margin-bottom: 20px;
                background: $quaternaryColor;

                .pointer {
                    position: relative;
                    left: 0;
                    top: -100px;
                }
            }
            ul {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                row-gap: 15px;
                column-gap: 20px;
                li {
                    display: flex;
                    position: relative;
                    z-index: 1;
                    flex-direction: column;
                    color: $quaternaryColor;

                    .topBody {
                        display: flex;
                        flex-direction: row;
                        cursor: pointer;
                    }
                    .bottomBody {
                        display: flex;
                        width: 100%;
                    }
                    .description {
                        display: none;
                        position: relative;
                        top: 0;
                        background: #f9f9fa;
                        font-size: 16px;
                        padding: 20px;
                        z-index: 1;
                        width: 100%;
                        a {
                            text-transform: lowercase;
                            font-style: italic;
                            color: #0b3691;
                            text-decoration: underline;
                            font-weight: 600;
                            i {
                                margin-left: 5px;
                            }
                        }
                    }
                    .icon {
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #f9f9fa;
                        position: relative;
                        z-index: 2;
                        i {
                            font-size: 12px;
                            z-index: 2;
                        }
                        i[class*="minus"] {
                            display: none;
                        }
                        i[class*="plus"] {
                            display: block;
                        }
                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                    .name {
                        display: flex;
                        font-size: 16px;
                        font-weight: 500;
                        margin-left: 20px;
                        align-items: center;
                        position: relative;
                        z-index: 2;
                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                    &.active {
                        z-index: 2;
                        opacity: 1;
                        .icon {
                            i[class*="plus"] {
                                display: none;
                            }
                            i[class*="minus"] {
                                display: block;
                            }
                        }
                        .description {
                            display: block;
                        }
                    }
                }
            }
        }
        &.active {
            li {
                opacity: 0.4;
            }
        }
    }
}
