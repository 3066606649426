@import "main_variables.scss"; 

$mainFont: 'Rubik', 'Tahoma', sans-serif;
$secundaryFont: 'Rubik', 'Tahoma', sans-serif;
$tertiaryFont: 'Rubik', 'Tahoma', sans-serif;

$fontSizeH1: 2.5rem;
$fontLHeightH1: 3rem;

$fontSizeSmallH1: 2rem;
$fontLHeightSmallH1: 2.5rem;

$fontHomeSizeH1: 4.5rem;
$fontHomeLHeightH1: 4.5rem;

$fontHomeSizeH1Small: 2.5rem;
$fontHomeLHeightH1Small: 2.5rem;

$fontSizeH2: 1.4rem;
$fontLHeightH2: 2rem;

$fontSizeH3: 1.2rem;
$fontLHeightH3: 1.7rem;

$fontSizeText: .9rem;
$fontLHeightText: 1.5rem;

$fontSizeSmallText: .7rem;
$fontLHeightSmallText: .9rem;

$fontSizeBtn: .77rem;
$fontLHeightBtn: .9rem;

$fontSizeSmallBtn: .7rem;
$fontLHeightSmallBtn: .8rem;

$fontSizeBigTitle:5rem;
$fontLHeightBigTitle:5.5rem;

$fontSizeTitle:2rem;
$fontLHeightTitle:2.5rem;

$fontSizeSubTitle:1.4rem;
$fontLHeightSubTitle:1.8rem;

$fontSizeTitleCards: 1.28rem;
$fontLHeightTitleCards: 1.4rem;

$fontSizeTitleLabel: .9rem;
$fontLHeightTitleLabel: 1.2rem;

$fontSizePostP: 1.1rem;
$fontLHeightPostP: 1.85rem;

$fontSizeMenu: .8rem;
$fontSizeSubMenu: .8rem;
