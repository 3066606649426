.faqs {
    h5 {
        padding: 5px 20px;
        font-size: 16px;
        font-weight: 400;
    }
}

.faqs {
    .card-header {
        cursor: pointer;
        padding: 0.6rem 0.8rem;
        padding: 5px 20px;
        border-bottom: 0;
        border: 0px solid #081131;
        border-radius: 5px;
        margin-bottom: 2px;
        color: white;
        background: $mainColor;
    }
}

.faqs .card-header .btn {
    color: white;
}
.faqHeader {
    font-size: 27px;
    margin: 20px;
}

.panel-heading [data-toggle="collapse"]:after {
    font-family: "Glyphicons Halflings";
    content: "e072";
    float: right;
    color: #f58723;
    font-size: 18px;
    line-height: 22px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.panel-heading [data-toggle="collapse"].collapsed:after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    color: #454444;
}
