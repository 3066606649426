.homepage {
    .bannerSection {
        color: white;
        height: 50vh;
        min-height: 400px;
        background-size: cover;
        background-position: top center;
        position: relative;
        display: flex;
        align-items: center;
        &:after,
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            opacity: 0.8;
            background: linear-gradient(90deg, $bannerColor 0%, $bannerColor 15%, $bannerColor00 50%, $bannerColor 85%, $bannerColor 100%);
        }
        &:before {
            z-index: 1;
        }
        .title {
            position: relative;
            z-index: 3;
            line-height: 70px;
            margin-top: 50px;
            h1 {
                font-size: $fontHomeSizeH1;
                line-height: $fontHomeLHeightH1;
                color: $bannerColortext;

                strong {
                    color: $secundaryColor;
                }
                em {
                    font-size: $fontHomeSizeH1Small;
                    font-weight: 800;
                    line-height: $fontHomeLHeightH1Small;
                    font-style: normal;
                }
            }
        }
        .description {
            position: relative;
            z-index: 3;
            margin-top: 20px;
            font-size: $fontSizeText;
            line-height: $fontLHeightText;
            max-width: 60%;
            color: $bannerColortext;
        }
    }
    .betting {
        position: relative;
        z-index: 2;
        &:after {
            content: "";
            width: 100%;
            height: 30vh;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            background: $bannerColor;
        }
        &:before {
            content: "";
            width: 100%;
            height: 10vh;
            position: absolute;
            z-index: 0;
            top: -10vh;
            left: 0;
            background: $bannerColor;
            background: linear-gradient(180deg, $bannerColor00 0%, $bannerColor 100%);
        }
        .wrapper {
            border-radius: 10px;
            padding: 30px 40px;
            position: relative;
            z-index: 1;
            background: $bgColor;

            .header {
                align-items: center;
                margin-bottom: 25px;
                .name {
                    font-weight: 400;
                    font-size: 28px;
                    h2 {
                        font-weight: 400;
                        margin: 0;
                    }
                    strong {
                        font-size: $fontSizeH2;
                        font-weight: 700;
                        line-height: $fontLHeightH2;
                        display: inline-block;
                        width: 100%;
                    }
                }
                .description {
                    font-size: $fontSizeText;
                    line-height: $fontLHeightText;
                }
            }
            ul {
                li {
                    margin-bottom: 10px;
                    .button {
                        &:hover {
                            color: white;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
    .highlight {
        margin: 60px 0;
        background-position: center;
        background-size: cover;
        padding: 90px 0 10px;
        position: relative;
        color: $homeHighlightHoverText;

        &:after {
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 1;
            background-color: $homeHighlightHoverBg;
        }
        .container {
            position: relative;
            z-index: 10;
        }
        ul {
            li {
                padding-bottom: 80px;
                .title {
                    font-size: $fontSizeTitle;
                    font-weight: 800;
                    line-height: $fontLHeightTitle;
                    span {
                        font-size: $fontSizeSubTitle;
                        line-height: $fontLHeightSubTitle;
                        font-weight: normal;
                        display: inline-block;
                        width: 100%;
                    }
                }
                .description {
                    font-size: $fontSizeText;
                    line-height: $fontLHeightText;
                }
            }
        }
    }
    .bonus {
        padding-bottom: 100px;
        .header {
            //padding-right: 100px;
            .title,
            h2 {
                margin-bottom: 30px;
                font-weight: 900;
                text-align: left;
                margin-top: 0;
                font-size: $fontSizeTitle;
                line-height: $fontLHeightTitle;
                strong {
                    font-size: $fontSizeBigTitle;
                    line-height: $fontLHeightBigTitle;
                    display: inline-block;
                    font-weight: 800;
                    width: 100%;
                }
            }
            .description {
                font-size: $fontSizeText;
                line-height: $fontLHeightText;
            }
        }
        .wrapper {
            margin-top: 2rem;
            .sliderBonus {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 20px;
                .slick-disabled {
                    opacity: 0.2;
                }
                .slick-track {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    column-gap: 20px;
                }
                .item {
                    flex: 1 1 0px;
                }
                .arrow {
                    font-size: 30px;
                    color: $textColor;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .features {
        &.halfWrapper {
            background-color: $quaternaryColor;

            .halfContained {
                display: flex;
                height: 100%;
                &:before {
                    content: "";
                    position: absolute;
                    left: -100%;
                    right: 0px;
                    bottom: 0px;
                    top: 0px;
                    z-index: 0;
                    background-color: $mainColor;
                }
                .content-mobile {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    img {
                        object-fit: contain;
                        height: auto;
                        max-height: 100%;
                    }
                }
            }
        }
    }
    .whichBest {
        background: rgba(#1d2541, 0.02);
        color: black;
        text-align: left;
        padding: 50px 0 70px;
        .data {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
        }
        h2 {
            color: black;
            padding: 0;
            margin: 0;
            font-size: 32px;
            font-weight: 900;
            margin-bottom: 30px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
        }
        .image {
            img {
                height: auto;
            }
        }
    }
    .odds {
        padding: 70px 0;
        background: rgba(#e0e0e0, 0.2);
        .data {
            .title {
                font-weight: 900;
                font-size: $fontSizeH1;
                line-height: $fontLHeightTitle;
                text-transform: uppercase;
                color: $mainColor;
                margin-bottom: 30px;
            }
            .description {
                line-height: $fontLHeightText;
            }
            .button {
                margin-top: 30px;
                display: inline-block;
                line-height: 45px;
                padding: 0 20px;
                color: black;
                text-transform: uppercase;
                background: $secundaryColor;
            }
        }
        .slider {
            position: relative;
            z-index: 2;
            .arrow {
                font-size: 30px;
                color: $textColor;

                &:before {
                    display: none;
                }
            }
        }
    }
    .faqs {
        padding: 70px 0 40px;
        .image {
            padding-right: 50px;
            img {
                max-width: 100%;
                max-height: 100%;
                height: auto;
            }
        }
        .description {
            li {
                padding-bottom: 40px;
                h2 {
                    font-size: 24px;
                    font-weight: 600;
                    margin-top: 0;
                }
                p {
                    font-size: 16px;
                    line-height: 24px;
                    color: #6e6e6e;
                }
            }
        }
    }
}
