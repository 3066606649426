.ads {
    padding: 25px 15px;
    color: white;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgb(16, 39, 90);
        background: linear-gradient(180deg, rgba(16, 39, 90, 0.47) 0%, rgba(4, 20, 52, 1) 100%);
        opacity: 0.8;
        z-index: 1;
    }
    .wrapper {
        position: relative;
        z-index: 2;
        font-size: 26px;
        line-height: 30px;
        .label {
            font-weight: 500;
        }
        .title {
            color: var(--reviewSecundaryColor);
            font-weight: 900;
            b {
                color: white;
            }
        }
        .button {
            margin-top: 30px;
        }
    }
}

.ads_affiliate {
    padding: 1.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 97%;
    background: black;
    &:after {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 3;
        background: $bgColor url("../../images/adsDefault.webp") no-repeat bottom center;
        background-blend-mode: luminosity;
        background-size: cover;
        opacity: 0.5;
    }
    &:before {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 5;
    }
    .logo {
        text-align: left;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 8;
        img {
            max-height: 80%;
            max-width: 80%;
            object-fit: contain;
        }
    }
    .data {
        margin-top: 5px;
        width: 100%;
        text-align: center;
        display: inline-block !important;
        font-size: $fontSizeH1;
        font-weight: 800;
        text-transform: capitalize;
        padding: 5px 0px;
        border-radius: 5px;
        position: relative;
        z-index: 8;
        line-height: $fontSizeH1;
        color: white;
        .bonusTitle {
            font-size: 1.2rem;
            line-height: $fontLHeightH2;
            font-weight: 500;
        }
        .bonusText {
            font-size: 1.8rem;
            font-weight: 800;
            line-height: 1.8rem;
        }
    }
    .action {
        margin-top: 10px;
        position: relative;
        z-index: 8;
        text-align: center;
        margin-bottom: 30px;
        .button {
            color: white;
            text-transform: uppercase;
            padding: 0.8em;
            text-align: center;
            line-height: 18px;
            height: auto;
            display: inline-block;
            position: relative;
            animation: zoom-in-zoom-out 2s ease-out infinite;
            font-size: 0.7rem;
            background: $bonusItemBtnBg;
            color: $bonusItemBtnText;

            &:hover {
                opacity: 0.8;
                color: $bonusItemBtnText;
            }
            &.secundary {
                margin-top: 10px;
                background: $bonusItemBtnReviewBg;
                border: 1px solid $bonusItemBtnReviewBorder;
                color: $bonusItemBtnReviewText;
            }
            &:after {
                content: "";
                border-radius: 8px;
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                box-shadow: 0 0 1.5rem 0 $mainColor;
                opacity: 0;
                /* -webkit-animation: fadeAnimation 3.5s infinite cubic-bezier(0.165, 0.84, 0.44, 1); */
                animation: fadeAnimation 2s infinite cubic-bezier(0.165, 0.84, 0.44, 1);
            }
        }
    }
}

//Animations
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}
