.reviews {
    .bonus .button,
    .bannerReview .button,
    .ads .button {
        background: rgb(var(--reviewMainColor));
        text-transform: uppercase;
        transition: all 300ms ease-in-out;
        span {
            transition: all 300ms ease-in-out;
            margin-left: 5px;
            color: var(--reviewSecundaryColor);
        }
        &:hover {
            background: var(--reviewSecundaryColor);
            color: #2e2e2e;
            span {
                color: #2e2e2e !important;
            }
        }
    }
    .body {
        .contentBody > h2:first-child {
            margin-top: 0px;
        }
        .categoryContent {
            display: inline-block;
            vertical-align: middle;
            .cat {
                margin-top: 2rem;
                text-transform: uppercase;
                display: inline-block;
                padding: 5px 10px 4px 10px;
                font-size: $fontSizeBtn;
                border-radius: 2px;
                font-weight: 600;
                vertical-align: middle;
                background: $secundaryColor;
                color: $secundaryColorHoverText;
            }
        }
        .contentInfos {
            display: block;
            width: 100%;
            padding-bottom: 0px;
        }
    }
    .bannerReview {
        height: 260px;
        background: rgb(var(--reviewMainColor));
        position: relative;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: auto 100%;
        color: white;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(
                90deg,
                rgba(var(--reviewMainColor), 0) 15%,
                rgba(var(--reviewMainColor), 0.35) 20%,
                rgba(var(--reviewMainColor), 0.95) 30%,
                rgba(var(--reviewMainColor), 1) 50%,
                rgba(var(--reviewMainColor), 1) 100%
            );
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgb(0, 0, 0);
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
            opacity: 0.5;
            z-index: 2;
        }
        .data {
            position: relative;
            z-index: 3;
            display: grid;
            grid-template-columns: 20% 15% 1fr;
            grid-gap: 20px;
            padding-top: 55px;
            .logo {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                .logoBanner {
                    padding-bottom: 1rem;
                    width: 100%;
                    text-align: center;
                }
                img {
                    max-height: 85px;
                    max-width: 70%;
                }
                .action {
                    justify-content: center;
                    display: flex;
                    .button {
                        margin-top: 10px;
                        padding: 0px 30px;
                    }
                }
                .categoryContent {
                    text-align: center;
                    h1 {
                        margin: 0;
                        margin-top: 10px;
                        padding: 0;
                        font-size: 48px;
                        font-weight: 900;
                    }
                    .cat {
                        margin-top: 1.5rem;
                        text-transform: uppercase;
                        color: #2e2e2e;
                        display: inline-block;
                        padding: 5px 10px 4px 10px;
                        font-size: 20px;
                        border-radius: 2px;
                        font-weight: 600;
                        background: var(--reviewSecundaryColor);
                    }
                }
            }
            .reviewData {
                .age {
                    display: flex;
                    font-size: $fontSizeSmallText;
                    line-height: 14px;
                    font-weight: 600;
                    align-items: center;
                    column-gap: 15px;
                    img {
                        height: 36px;
                    }
                }
                .starts {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 50px;
                    ul {
                        display: flex;
                        li {
                            margin: 0 2px;
                            i {
                                font-size: 20px;
                                color: var(--reviewSecundaryColor);
                            }
                        }
                    }
                    .description {
                        font-weight: 600;
                        font-size: $fontSizeH3;
                        padding-top: 0.5rem;
                    }
                }
            }
            .specs {
                ul {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    row-gap: 10px;
                    column-gap: 10px;
                    li {
                        background: rgba(255, 255, 255, 0.1);
                        height: 70px;
                        border-radius: 5px;
                        padding: 0 0 0 15px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        font-size: $fontSizeMenu;
                        font-weight: 800;
                        line-height: $fontSizeMenu;
                        .name {
                            font-weight: 400;
                            display: flex;
                            align-items: center;
                            font-size: $fontSizeMenu;
                            .icon {
                                margin-right: 6px;
                                .image {
                                    width: 22px;
                                    height: 22px;
                                    background: var(--reviewSecundaryColor);
                                }
                            }
                        }
                        .resume {
                            margin-left: 30px;
                            height: 18px;
                            display: flex;
                            column-gap: 15px;
                            .item {
                                img {
                                    height: 18px;
                                }
                            }
                            .listPayments {
                                display: flex;
                                position: relative;
                                margin-top: 10px;
                                width: 100%;
                                padding-right: 10px;
                                &:hover {
                                    ul {
                                        &.drop {
                                            display: grid;
                                            grid-template-columns: repeat(3, minmax(0, 1fr));
                                            align-content: center;
                                            align-items: center;
                                            position: absolute;
                                            top: calc(100% + 10px);
                                            left: -5px;
                                            background: #ffffff;
                                            box-shadow: 0px 10px 10px rgba(114, 114, 114, 0.2);
                                            padding: 10px;
                                            border-radius: 5px;
                                        }
                                        img {
                                            -webkit-filter: none;
                                            filter: none;
                                            opacity: 1;
                                        }
                                    }
                                }

                                ul {
                                    width: 100%;
                                    display: grid;
                                    grid-template-columns: repeat(4, minmax(0, 1fr));
                                    align-content: center;
                                    align-items: center;
                                    column-gap: 0px;
                                    gap: 10px;
                                    &.drop {
                                        display: none;
                                    }
                                    li {
                                        order: 1;
                                        background: transparent;
                                        background: #ffffff50;
                                        height: 100%;
                                        padding: 2px;
                                        margin: 0;
                                        &.more {
                                            order: 1000;
                                            align-items: center;
                                            .btnMorePayments {
                                                display: inline-block;
                                                border-radius: 5px;
                                                font-weight: 600;
                                                padding: 3px 3px;
                                                width: 60%;
                                            }
                                        }
                                        &.hide {
                                            display: none;
                                        }
                                        text-align: center;
                                        margin-bottom: 4px;
                                        img {
                                            max-height: 26px;
                                            max-width: 70%;
                                            height: auto;
                                            display: inline-block;
                                            -o-object-fit: contain;
                                            object-fit: contain;
                                            opacity: 0.5;
                                            margin: auto;
                                            filter: brightness(0) invert(1);
                                            &.isHighlight {
                                                filter: none;
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .bonus {
        height: 70%;
        border-radius: 5px;
        .wrapper {
            padding: 12px 12px 0;
            .name {
                display: flex;
                column-gap: 20px;
                flex-direction: row;
                align-content: center;
                justify-content: center;
                align-items: center;
                .title {
                    flex: 1;
                    font-size: 20px;
                    font-weight: 900;
                    text-transform: uppercase;
                    color: $titleColor;
                    span {
                        width: 100%;
                        display: inline-block;
                        line-height: 38px;
                        font-size: 44px;
                    }
                }
                .pros {
                    margin-top: 5px;
                    flex: 1;
                    li {
                        font-size: 12px;
                        font-weight: 800;
                        line-height: 14px;
                        margin-bottom: 5px;
                        display: flex;
                        i {
                            color: #4fb576;
                            margin-right: 8px;
                        }
                    }
                }
            }
            .block {
                border-radius: 5px;
                overflow: hidden;
                margin-top: 10px;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
                border: 1px solid #e5e5e5;
                .logo {
                    text-align: center;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff;
                    padding: 1rem;
                    img {
                        padding: 0px;
                        min-height: 30px;
                        max-height: 50px;
                        object-fit: contain;
                    }
                }
                .specs {
                    background-color: #e8e8e8;
                    padding: 10px 20px;
                    ul {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        li {
                            text-align: center;
                            font-size: $fontSizeSmallText;
                            line-height: 18px;
                            flex: 1 1 0px;
                            span {
                                width: 100%;
                                display: inline-block;
                                font-size: $fontSizeSubTitle;
                                margin-top: 10px;
                                font-weight: 800;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
                .action {
                    padding: 15px 20px;
                }
            }
        }
    }
    .titleSectionReview {
        padding: 2rem 0px 1rem 0px;
        h1 {
            padding: 0;
            margin: 0;
            font-size: $fontSizeSmallH1;
            line-height: $fontLHeightSmallH1;
            font-weight: 800;
        }
    }
    .descriptionSection {
        background: rgba(#1d2541, 0.02);
        text-align: left;
        padding: 50px 0 100px;
        font-size: $fontSizePostP;
        line-height: $fontLHeightPostP;
        a {
            color: $urlColor;
        }
    }

    &.inCategory {
        .bannerReview {
            &:before {
                background: linear-gradient(
                    90deg,
                    rgba(var(--reviewMainColor), 0) 15%,
                    rgba(var(--reviewMainColor), 0.35) 20%,
                    rgba(var(--reviewMainColor), 0.95) 30%,
                    rgba(var(--reviewMainColor), 1) 50%,
                    rgba(var(--reviewMainColor), 1) 70%,
                    rgba(1, 20, 59, 1) 100%
                );
            }
        }
    }

    &.all {
        .bannerPost {
            position: relative;
            background-repeat: no-repeat;
            background-position: left center;
            color: white;
            background-size: auto 100%;
            background: $bannerColor;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: linear-gradient(90deg, $bannerColor00 0%, $bannerColor 30%, $bannerColor 50%, $bannerColor 85%, $bannerColor 100%);
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgb(0, 0, 0);
                background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                opacity: 0.5;
                z-index: 2;
            }
            .data {
                position: relative;
                z-index: 3;
                height: 260px;
                display: flex;
                align-items: center;
                &.smallBanner {
                    height: 140px;
                    .title {
                        padding-left: 20px;
                    }
                }
                .row {
                    width: 100%;
                }

                .title {
                    padding-left: 70px;
                    h1 {
                        margin: 0;
                        padding: 0;
                        font-size: 40px;
                        font-weight: 700;
                        letter-spacing: -1px;
                        color: #ffffff;
                    }
                }
            }
        }
        .cards {
            .listCards {
                display: grid;
                grid-template-columns: repeat(5, minmax(0, 1fr));
                column-gap: 16px;
                row-gap: 8px;
                .item {
                    flex: 1 1 0px;
                }
            }
        }
    }

    .sectionBtnMore {
        display: block;
        text-align: center;
        padding: 2rem 0px;
        .btnViewMore {
            display: inline-block;
            padding: 10px 3rem;
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            border-radius: 5px;
            text-transform: uppercase;
            background-color: $quaternaryColor;
            &:hover {
                opacity: 0.9;
                cursor: pointer;
            }
        }
    }
}
